import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, logoutUser, removeStorageData } from "../../../framework/src/Utilities";
import moment from 'moment';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start

export interface ImageData {
  file_name: string;
  content_type: string;
  image: unknown;
}
export interface AddressData {
  id: string;
  buildingNo: string;
  street: string;
  state: string;
  country: string;
  postalCode: string;
}

export interface UserData {
  id: string;
  profilePic: string;
  name: string;
  email: string;
  phoneNumber: string;
  birthDate: string;
  gender: string;
  address: AddressData;
  createdAt: string;
}

export interface AddressRes {
  address: string;
  country: string;
  zip_code: string;
  state: string;
  gender: string;
  street: string;
  birthday: string;
}

export interface UserDataRes {
  id: string;
  attributes: {
    user_name: string;
    full_phone_number: string;
    email: string;
    custom_form_attributes: AddressRes[];
    file_url: string;
    created_at: string;
  }
}

export interface NewPasswordError {
  characterError: boolean;
  smallCharacterError: boolean;
  capitalCharacterError: boolean;
  numberSymbolError: boolean;
}

export interface PasswordData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  currentPasswordError: boolean;
  newPasswordError: boolean;
  confirmPasswordError: boolean;
}

export interface ErrorData {
  nameError: boolean;
  emailError: boolean;
  phoneNumberError: boolean;
  birthDateError: boolean;
  genderError: boolean;
  buildingNoError: boolean;
  countryError: boolean;
  streetError: boolean;
  postalCodeError: boolean;
  stateError: boolean;
}

interface DateFormatOptions {
  year: 'numeric' | '2-digit';
  month: 'short' | 'long' | 'numeric' | '2-digit';
  day: 'numeric' | '2-digit';
}
// Customizable Area End

interface S {
  // Customizable Area Start
  userData: UserData;
  settingId: number;
  imageValidateError: boolean;
  tempUserData: UserData;
  token: string;
  isEdit: boolean;
  isModalOpen: boolean;
  modalType: string;
  passwordData: PasswordData;
  countryList: string[][];
  stateList: string[];
  dateError: boolean;
  errorPopShow: boolean;
  newPasswordErrors: NewPasswordError;
  errorData: ErrorData;
  isNotificationEnabled: boolean;
  selectedCountry: string;
  // Customizable Area End
}
interface SS {}

export default class UserProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserDataCallId: string = "";
  updateUserDataCallId: string = "";
  changePasswordCallId: string = "";
  disableAccountCallId: string = "";
  getCountriesDataCallId: string = "";
  getStatesDateCallId: string = "";
  getNotificationCallId: string = "";
  updateNotificationCallId: string = "";
  matchCurrentPasswordCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      settingId: 0,
      userData: {
        id: "",
        profilePic: "",
        name: "",
        email: "",
        phoneNumber: "",
        birthDate: "",
        gender: "",
        createdAt: "",
        address: {
          id: "",
          buildingNo: "",
          country: "",
          street: "",
          postalCode: "",
          state: ""
        }
      },
      imageValidateError: false,
      errorPopShow: false,
      isNotificationEnabled: false,
      stateList: [],
      dateError: false,
      countryList: [],
      tempUserData: {
        id: "",
        profilePic: "",
        name: "",
        email: "",
        phoneNumber: "",
        birthDate: "",
        gender: "",
        createdAt: "",
        address: {
          id: "",
          buildingNo: "",
          country: "",
          street: "",
          postalCode: "",
          state: ""
        }
      },
      errorData: {
        nameError: false,
        emailError: false,
        phoneNumberError: false,
        birthDateError: false,
        genderError: false,
        buildingNoError: false,
        countryError: false,
        streetError: false,
        postalCodeError: false,
        stateError: false,
      },
      isModalOpen: true,
      isEdit: false,
      modalType: "",
      token: "",
      passwordData: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
        currentPasswordError: false,
        newPasswordError: false,
        confirmPasswordError: false,
      },
      newPasswordErrors: {
        characterError: false,
        smallCharacterError: false,
        capitalCharacterError: false,
        numberSymbolError: false,
      },
      selectedCountry: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCountriesData();
    let token = await getStorageData('authToken')
    this.setState({ token });
    this.getUserData(token);
    this.getNotification(token);
    this.getStatesData('United States of America');
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        this.handleResponse(webApiRequestCallId, webResponseJson);
      }
    }
    // Customizable Area End
  }
  
  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.userData !== this.state.userData){
      if(!prevState.userData.profilePic || (prevState.userData.profilePic === this.state.userData.profilePic)){
        this.setState({ tempUserData: this.state.userData })
      }
    }
  }

  handleResponse = (webApiRequestCallId: string, webResponseJson: any) => {
    switch(webApiRequestCallId){
      case this.getUserDataCallId:
        if(webResponseJson.data){
          this.setUserData(webResponseJson.data);
        }
        break;
      case this.updateUserDataCallId:
        this.getUserData();
        break;
      case this.changePasswordCallId:
        if(webResponseJson.error){
          this.setState({ isModalOpen: true, modalType: 'update-password', passwordData: {...this.state.passwordData, currentPasswordError: true, confirmPasswordError: false }})
        }else{
          this.clearPasswordData();
          removeStorageData('basicInfo');
          removeStorageData('questionsAttributes');
          removeStorageData('documents');
          removeStorageData('userId');
          removeStorageData('userDetail');
          removeStorageData('userData');
          logoutUser();
          this.navigateToLogin();
        }
        break;
      case this.getCountriesDataCallId:
        this.setState({ countryList: webResponseJson })
        break;
      case this.getStatesDateCallId:
        this.setState({ stateList: webResponseJson })
        break;
      case this.disableAccountCallId:
        this.navigateToLogin();
        break;
      case this.getNotificationCallId:
        if(webResponseJson.data){
          this.setState({ isNotificationEnabled: webResponseJson.data.attributes.sms_notification });
        }
        break;
      case this.updateNotificationCallId:
        this.getNotification();
        break;
      case this.matchCurrentPasswordCallId:
        if(webResponseJson.error && webResponseJson.error === "Current password is Incorrect"){
          this.setState({ passwordData: { ...this.state.passwordData, currentPasswordError: true}});
        }else{
          this.setState({ modalType: "update-password-confirmation" })
        }
    }
  }

  navigateToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    logoutUser();
  }

  getUserData = (tokenData?: string) => {
    const webHeader = {
      token: tokenData ?? this.state.token,
    };
    const webRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDataCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.userDataGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  matchWithCurrentPassword = () => {
    const webHeader = {
      token: this.state.token,
    };

    const webMatchCurrentPasswordRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    webMatchCurrentPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    this.matchCurrentPasswordCallId = webMatchCurrentPasswordRequestMessage.messageId;
    webMatchCurrentPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.matchCurrentPasswordEndpoint}?current_password=${this.state.passwordData.currentPassword}`
    );

    webMatchCurrentPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webMatchCurrentPasswordRequestMessage.id, webMatchCurrentPasswordRequestMessage);
  }

  getCountriesData = () => {
    const webRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountriesDataCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getContriesUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  isOlderThan18Years = (dateString: string) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const dob = new Date(year, month - 1, day);
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return dob <= eighteenYearsAgo;
  }

  getNotification = (token?: string) => {
    const notificationWebHeader = {
      token: token ?? this.state.token,
      "Content-Type": webConfigJSON.dashboarContentType,
    }

    const webRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(notificationWebHeader)
    );

    this.getNotificationCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), webConfigJSON.getNotificationEndpoint
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  handleNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const notificationWebHeader = {
      token: this.state.token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };

    const body = {
      "setting": {
        "sms_notification": event.target.checked
      }
    };
    
    const webRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(notificationWebHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.updateNotificationCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.notificationEndpoint}/${this.state.settingId}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.putMethod
    );

    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  getStatesData = (country: string) => {
    const webRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStatesDateCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getStatesUrl.replace(':state', 'us')
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  getCalendarMaxDate = () => {
    const currentDate = new Date();
    return new Date(currentDate.getFullYear() - 18, currentDate.getMonth(),currentDate.getDate());
  }

  getCalendarMinDate = () => {
    const currentDate = new Date();
    return new Date(currentDate.getFullYear() - 70, currentDate.getMonth(),currentDate.getDate());
  }

  getNameErrorText = () => {
    const { name } = this.state.tempUserData;
    if(name.trim().length < 3){
      return webConfigJSON.minCharacterNameErrorText;
    }else if(name.trim().length > 50){
      return webConfigJSON.maxCharacterNameErrorText;
    }else{
      return webConfigJSON.nameErrorText;
    }
  }

  getPhoneErrorText = () => {
    if(!this.state.tempUserData.phoneNumber){
      return webConfigJSON.phoneErrorText;
    }else if(this.state.tempUserData.phoneNumber.length > 10){
      return webConfigJSON.phoneErrorNotExceedText;
    }else{
      return webConfigJSON.phoneErrorMaximumText;
    }
  }

  updateUserData = (isImgUpload: boolean, fileData?: ImageData) => {
    const { name, email, phoneNumber, address, gender, birthDate} = isImgUpload ? this.state.userData : this.state.tempUserData;
    const { id, buildingNo, country, postalCode, state, street} = address;
    const webHeader = {
      token: this.state.token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const httpBody = {
      data: {
        attributes: {},
      }
    };
    if(fileData){
      httpBody.data.attributes = {
        user_avatar: fileData
      }
    }else{
      httpBody.data.attributes = {
        user_name: name,
        email,
        full_phone_number: phoneNumber,
        custom_form_attributes: {
          id,
          address: buildingNo,
          country,
          zip_code: postalCode,
          state,
          gender,
          street,
          birthday: birthDate,
        }
      }
    }

    const webRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserDataCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.userDataUpdateUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.putMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  };

  changePasswordConfirm = () => {
    const { currentPassword, newPassword } = this.state.passwordData;
    const webHeader = {
      token: this.state.token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const httpBody = {
      old_password: currentPassword,
      new_password: newPassword
    };
    const webRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePasswordCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.changePasswordUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.putMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  disableAccountConfirm = () => {
    const webHeader = {
      token: this.state.token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const httpBody = {};
    const webRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.disableAccountCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.disableAccountUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.putMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  getFormattedDate = () => {
    const date =  moment(this.state.tempUserData.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    if(date !== "Invalid date"){
      return date;
    }
    return "";
  }

  clearPasswordData = () => this.setState({ 
    isModalOpen: false, 
    passwordData: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      currentPasswordError: false,
      newPasswordError: false,
      confirmPasswordError: false
    },
    newPasswordErrors: {
      characterError: false,
      smallCharacterError: false,
      capitalCharacterError: false,
      numberSymbolError: false,
    }
  });

  getCustomValidate = (name: "name" | "email" | "phoneNumber") => name === "email" ? !this.state.tempUserData.email.match(webConfigJSON.emailRegex) : !this.state.tempUserData[name];

  getInputFieldError = (isError: boolean) => {
    if(isError){
      return "input-error"
    }else{
      return ""
    }
  }

  handleValidation = () => {
    const nameError = !this.state.tempUserData.name || !webConfigJSON.nameRegex.test(this.state.tempUserData.name.trim());
    const emailError = !this.state.tempUserData.email && !this.getCustomValidate("email");
    const phoneNumberError = !this.state.tempUserData.phoneNumber || this.state.tempUserData.phoneNumber.length !== 10;
    const birthDateError = !this.state.tempUserData.birthDate;
    const genderError = !this.state.tempUserData.gender;
    const buildingNoError = !this.state.tempUserData.address.buildingNo;
    const countryError = !this.state.tempUserData.address.country;
    const streetError = !this.state.tempUserData.address.street;
    const postalCodeError = !this.state.tempUserData.address.postalCode || !/^\d{5,6}$/.test(this.state.tempUserData.address.postalCode);
    const stateError = !this.state.tempUserData.address.state;
    this.setState({
      errorData: {
        nameError,
        emailError,
        phoneNumberError,
        birthDateError,
        genderError,
        buildingNoError,
        countryError,
        streetError,
        postalCodeError,
        stateError,
      }
    })
    return (nameError || emailError || phoneNumberError || birthDateError || genderError || buildingNoError || countryError || streetError || postalCodeError || stateError || this.getCustomValidate("name") || this.getCustomValidate("email") || this.getCustomValidate("phoneNumber") || !this.isOlderThan18Years(this.state.tempUserData.birthDate))
  }

  setUserData = (webResponseJson: any) => {
    const { user_name: name, full_phone_number: phoneNumber, email, custom_form_attributes, image_url: profilePic, created_at: createdAt, setting } = webResponseJson.attributes;
    const { address: buildingNo, country, zip_code: postalCode, state, gender, street, birthday: birthDate } = custom_form_attributes;
      
      const date = new Date(createdAt);
      const options: DateFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
      const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
      this.setState({
        settingId: setting.id,
        selectedCountry: 'United States of America',
        userData: {
          id: webResponseJson.id,
          name,
          profilePic,
          email,
          phoneNumber,
          birthDate: birthDate ?? "",
          gender: gender ?? "",
          createdAt: formattedDate,
          address: {
            id: custom_form_attributes.id,
            buildingNo: buildingNo ?? "",
            country: country ?? "",
            postalCode: postalCode ?? "",
            state : state ?? "",
            street: street ?? ""
          }
        }
      })
  };

  handleConfirmAction = () => {
    if(this.state.modalType === "update-password-confirmation"){
      this.changePasswordConfirm();
    }else if(this.state.modalType === "disable-account-confirmation"){
      this.disableAccountConfirm();
    }
    this.handleModalClose();
  }

  handleEdit = () => {
    if(this.state.isEdit){
      if(!this.isOlderThan18Years(this.state.tempUserData.birthDate)){
        this.setState({ dateError: true })
      }
      if(!this.handleValidation()){
        this.setState({ isEdit: false });
        this.updateUserData(false);
      }
    }else{
      this.setState({ isEdit: true })
    }
  }

  preventClose = (
      _: {},
      reason: "backdropClick" | "escapeKeyDown"
    ) => {
      if (reason !== "backdropClick") {
        this.handleModalClose();
      }
    };

  handleGenderChange = (value: string) => {
    if(this.state.isEdit){
      this.setState({ errorData: {...this.state.errorData, genderError: false }, tempUserData: { ...this.state.tempUserData, gender: value }});
    }
  }

  handleChangePassword = () => {
    this.setState({ isModalOpen: true, modalType: "update-password"})
  }

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if(!value.includes(' ')){
      if(name === "newPassword"){
        this.getNewPasswordErrorData(value);
      }else if(name === "currentPassword"){
        this.setState({ passwordData: {...this.state.passwordData, currentPasswordError: false, currentPassword: value }})
      }else{
        this.setState({ passwordData: { ...this.state.passwordData, [name]: value }});
      }
    }
  }

  setCountry =(value: string) => {
    this.setState({ selectedCountry: value });
  };

  handleCountryChange = (event: React.ChangeEvent<{}>,
    value: string | null | number | readonly string[],
    reason: AutocompleteChangeReason, 
    details?: AutocompleteChangeDetails<string | number | readonly string[]> | undefined) => {
    const selectedOption = this.state.countryList.find(item => item[1] === value);
    if (value && selectedOption){
        this.setState({ selectedCountry: selectedOption[1], tempUserData: {...this.state.tempUserData, address: {...this.state.tempUserData.address, country: selectedOption[0], state: ''}}, errorData: { ...this.state.errorData, countryError: false } });
    }
  }

  handleStateChange = (event: React.ChangeEvent<{}>,
    value: string | null | number | readonly string[],
    reason: AutocompleteChangeReason, 
    details?: AutocompleteChangeDetails<string | number | readonly string[]> | undefined) => {
      if(value){
        this.setState({ errorData: { ...this.state.errorData, stateError: false }, tempUserData: { ...this.state.tempUserData, address: {...this.state.tempUserData.address, state: value as string}}})
      }
    }

  handleModalClose = () => this.setState({ isModalOpen: false, modalType: "" })

  handleCancelActionButton = () => {
    if(this.state.modalType === "update-password-confirmation"){
      this.handleChangePassword();
    }else{
      this.handleModalClose();
    }
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, address: boolean, errorFieldName: string) => {
    const { name, value } = event.target;
    let tempUserData: UserData = this.state.tempUserData;
    if(name === "phoneNumber"){
      if(value === "" || /^\d+$/.test(value)){
        tempUserData = {...tempUserData, [name]: value}
      }
    }else if(name === "name"){
      if(value === "" || webConfigJSON.nameChangeRegex.test(value)){
        tempUserData = {...tempUserData, [name]: value}
      }
    }else{
      if(address){
        tempUserData = {...tempUserData, address: {...tempUserData.address, [name]: value}}
      }else{
        tempUserData = {...tempUserData, [name]: value}
        this.setState({ dateError: false });
      }
    }
    this.setState({ errorData: { ...this.state.errorData, [errorFieldName]: false }, tempUserData: tempUserData });
  }

  handleChangeVisibility = (name: string, value: boolean) => {
    this.setState({ passwordData: {...this.state.passwordData, [name]: !value}})
  }

  getPositionStyle = (name: string) => name === "newPassword" ? "relative" : "static";

  getGapStyle = (name: string) => name === "changePassword" ? "0px" : "16px";

  getNewPasswordErrorLabel = () => {
    const { newPassword, currentPassword } = this.state.passwordData;
    if(newPassword && (newPassword === currentPassword)){
      return webConfigJSON.newPasswordErrorText
    }else if(this.state.passwordData.newPasswordError && this.state.passwordData.newPassword.length === 0){
      return webConfigJSON.newPasswordRequiredText;
    }else if(this.state.passwordData.newPasswordError && !this.state.errorPopShow){
      return webConfigJSON.newPasswordInvalidText;
    }
    return '';
  }

  getCurrentPasswordErrorLabel = () => this.state.passwordData.currentPassword ? webConfigJSON.currentPasswordIncorrectErrorText : webConfigJSON.currentPasswordErrorText;

  handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ errorPopShow: event.target.name === "newPassword" });
  }

  handleBlur = () => {
    this.setState({ errorPopShow: false });
  }

  getNewPasswordErrorData = (newPassword: string) => {
    const characterError =  newPassword.trim().length < 8;
    const capitalCharacterError = !webConfigJSON.capitalCharacterRegex.test(newPassword)
    const smallCharacterError = !webConfigJSON.smallCharacterRegex.test(newPassword)
    const numberSymbolError = !webConfigJSON.numberSymbolRegex.test(newPassword)
      
    this.setState({
      passwordData: {
        ...this.state.passwordData,
        newPassword,
        newPasswordError: capitalCharacterError || smallCharacterError || characterError || numberSymbolError,
      },
      newPasswordErrors: {
        characterError,
        capitalCharacterError,
        smallCharacterError,
        numberSymbolError,
      }
    })
  }

  handlePasswordCriteria = () => this.state.passwordData.newPassword.match(webConfigJSON.passwordRegex);

  handleUpdatePassword = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state.passwordData;
    if(this.handlePasswordCriteria() && newPassword === confirmPassword && currentPassword && currentPassword !== newPassword){
      this.setState({ passwordData: { ...this.state.passwordData, currentPasswordError: false, confirmPasswordError: false, newPasswordError: false }})
      this.matchWithCurrentPassword();
    }else{
        this.setState({ passwordData: {
          ...this.state.passwordData,
          currentPasswordError: !(currentPassword),
          newPasswordError: (newPassword && currentPassword === newPassword) || !this.handlePasswordCriteria(),
          confirmPasswordError: newPassword.trim().length > 0 && newPassword !== confirmPassword,
        },
        newPasswordErrors: {
          characterError: newPassword.trim().length < 8,
          capitalCharacterError: !webConfigJSON.capitalCharacterRegex.test(newPassword),
          smallCharacterError: !webConfigJSON.smallCharacterRegex.test(newPassword),
          numberSymbolError: !webConfigJSON.numberSymbolRegex.test(newPassword),
        }
      })
    }
  }

  handleDisableAccount = () => this.setState({ isModalOpen: true, modalType: "disable-account-confirmation"})

  convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  handleFileRead = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const allowedTypes = ["image/jpeg", "image/png"];
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileName = file.name.split(".");
      const fileExtenstion = fileName[fileName.length - 1];
      if(allowedTypes.includes(file.type) || fileExtenstion === "heif" || fileExtenstion === "heic" ){
        this.setState({ imageValidateError: false })
        const base64 = await this.convertBase64(file);
        const { name, type } = file;
        const fileData = {
          file_name: name,
          content_type: type,
          image: base64
        }
        this.updateUserData(true, fileData)
      }else{
        this.setState({ imageValidateError: true })
      }
    }
  }

  handleKeyPress = (event: {
    target: any; charCode: any; preventDefault: () => void; 
    }) => {
    const charCode = event.charCode;
    if (event.target.name === "postalCode" && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };

  // Customizable Area End
}
