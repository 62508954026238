//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Box, styled, MenuItem, Select, Modal, Button } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { closeImg, dropdownImg, noInvoiceImg } from "./assets";
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInvoiceContent = (id: string, title: string, generatedOn: string, amount: string, status: string, pdf_url: string) => (
    <Box key={id} data-test-id={status} onClick={() => this.handleStatusClick(id, status, pdf_url)} style={{ background: this.getInvoiceContentBackground(status), borderColor: this.getInvoiceContentBorderColor(status)}} className={`invoice-content ${status.toLowerCase() === "paid" ? "pointer": ""}`}>
      <div>
        <div className="invoice-id-details">
          {configJSON.textHash}{id} <span>{configJSON.textGeneratedOn} {generatedOn}</span>
        </div>
        <div className="invoice-title desktop">{title}</div>
      </div>
      <div className="amount-invoice-label">
        <div>
          <div className="invoice-title mobile">{title}</div>
          <div className="amount-invoice-dash">
            <div className="amount-label">
              {configJSON.textAmount}
            </div>
            <div className="amount-dash">{configJSON.textDash}</div>
            <div className="invoice-amount">
              {configJSON.textDollar}{amount}
            </div>
          </div>
        </div>
        <div 
          className={`invoice-status`} 
          style={{ background: this.getInvoiceContentBorderColor(status)}}
        >
          {status}
        </div>
      </div>
    </Box>
  )

  getDropdownIcon = () => <img className="dropdown-icon" src={dropdownImg} alt="dropdown" />

  renderValue = () => this.state.yearSort !== "" ? <Box>{this.state.yearSort}</Box> : <Box style={{ color: "#9E9E9E"}}>{configJSON.textYear}</Box> 
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          screenName="InvoiceBilling"
          fullWidthNavigation={true}
          isDrawer={true}
          isLogo={true}
        />
        <ModalWrapper open={this.state.modal.isOpen}>
          <Box className="invoice-modal">
            <Box className="invoice-modal-header">
              <Box className="invoice-modal-id-text">{configJSON.textHash}{this.state.modal.id}</Box>
              <img onClick={this.handleModalClose} className="close-button" src={closeImg} alt="close-modal" />
            </Box>
            <Box className="invoice-detail-section">
              <Box className="invoice-detail-show">
                <iframe 
                  src={`${this.state.invoicePdf}#toolbar=0`} 
                  width="100%" 
                  height="100%" 
                />
              </Box>
              <Box className="button-section">
                <a href={this.state.invoicePdf} download >
                  <Button data-test-id="download-report" className="download-report">{configJSON.textDownloadReport}</Button>
                </a>
              </Box>
            </Box>
          </Box>
        </ModalWrapper>
        <InvoiceWrapper>
          <Box className="invoice-section">
            <Box className="invoice-header-section">
              <Box>{configJSON.textInvoices}</Box>
              <Select
                data-test-id="select-menu"
                className="select-menu"
                variant="outlined"
                value={this.state.yearSort}
                onChange={this.handleYearChange}
                IconComponent={this.getDropdownIcon}
                displayEmpty={true}
                renderValue={this.renderValue}
                MenuProps={invoiceMenuProp}
              >
                {this.state.yearSelectData.length > 0 && this.state.yearSelectData.map((item) => (
                  <MenuItemWrap disableRipple key={item} value={item}>
                    <Box className={item === this.state.yearSort ? 'active-menu' : 'not-active-menu'}>{item}</Box>
                  </MenuItemWrap>
                ))}
              </Select>
            </Box>
            <Box className="invoices-content">
              {this.state.invoicesData.length > 0 ?
                this.state.invoicesData.map((invoice) => this.renderInvoiceContent(invoice.id, invoice.title, invoice.generatedOn, invoice.amount, invoice.status, invoice.pdf_url))
                :
                <Box className="no-invoice-content">
                  <img className="no-invoice-image" src={noInvoiceImg} alt="no-invoice" />
                  <Box className="no-invoice-title">{configJSON.textForNoInvoice}</Box>
                  <Box className="no-invoice-description">{configJSON.textDescriptionForNoInvoice}</Box>
                </Box>
              }
            </Box>
          </Box>
        </InvoiceWrapper>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const invoiceMenuProp: Partial<MenuProps> = {
  getContentAnchorEl: null,
  anchorOrigin:{
    vertical: "bottom",
    horizontal: "left"
  },
  PaperProps: {
    style: {
      border: "1px solid rgba(1, 159, 72, 0.18)",
      borderRadius: "4px",
      boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
      background: "#FFF",
      marginTop: "4px",
    }
  },
  MenuListProps: {
    style: {
        padding: 0
    },
  },
  transformOrigin:{
    vertical: "top",
    horizontal: "left"
  },
}

const InvoiceWrapper = styled(Box)({
  background: "#F3FAF7",
  padding: "30px",
  color: "#333",
  fontFamily: "Urbanist",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  "@media only screen and (max-width: 767px)" : {
    background: "#FFF",
    padding: "0 24px 24px"
  },
  "& .invoice-section": {
    borderRadius: "20px",
    border: "1px solid rgba(1, 159, 72, 0.18)",
    background: "#FFF",
    boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
    padding: "30px",
    "@media only screen and (max-width: 767px)" : {
      padding: "16px",
      border: "1px solid #E3E3E3",
      boxShadow: "0px 2.11px 12.658px 0px rgba(0, 0, 0, 0.25)"
    },
  },
  "& .amount-invoice-label": {
    width: "50%", 
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center",
    "@media only screen and (max-width: 767px)" : {
      width: "100%", 
      alignItems: "end"
    },
    "& .amount-invoice-dash": {
      display: "block",
      "@media only screen and (max-width: 767px)" : {
        display: "flex",
        marginTop: "8px"
      }
    }
  },
  "& .amount-dash": {
    display: "none",
    "@media only screen and (max-width: 767px)" : {
      display: "block",
      fontSize: "12px"
    },
  },
  "& .dropdown-icon": {
    marginRight: "14px",
    pointerEvents: "none", 
    position: "absolute", 
    right: "0"
  },
  "& .invoice-header-section": {
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media only screen and (max-width: 767px)" : {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "normal",
    },
  },
  "& .invoices-content": {
    marginTop: "25px",
    display: "flex", 
    gap: "30px", 
    flexDirection: "column",
    "& .invoice-content": {
      display: "flex",
      gap: "12px",
      justifyContent: "space-between",
      borderRadius: "8px",
      borderLeft: "3px solid #FD8D02",
      background: "#FFF8EF",
      padding: "20px 30px",
      "@media only screen and (max-width: 767px)" : {
        padding: "12px",
        display: "block"
      },
    },
    "& .invoice-id-details": {
      fontSize: "20px", 
      fontWeight: 600, 
      lineHeight: "30px",
      "@media only screen and (max-width: 767px)" : {
        fontSize: "12px",
        fontWeight: 700,
        display: "flex",
        justifyContent: "space-between",
        lineHeight: "normal",
        marginBottom: "8px",
      },
      "&>span": {
        color: "#9E9E9E", 
        fontSize: "16px", 
        fontWeight: 500,
        "@media only screen and (max-width: 767px)" : {
          fontSize: "12px",
          fontWeight: 400,
          opacity: 0.5
        },
      }
    },
    "& .invoice-title": {
      fontSize: "18px", 
      lineHeight: "35px",
      "@media only screen and (max-width: 767px)": {
        fontSize: "12px", 
        lineHeight: "normal",
      }
    },
    "& .desktop": {
      display: "block",
      "@media only screen and (max-width: 767px)": {
        display: "none"
      }
    },
    "& .mobile": {
      display: "none",
      "@media only screen and (max-width: 767px)": {
        display: "block"
      }
    },
    "& .amount-label": {
      color: "#9E9E9E", 
      fontSize: "16px", 
      fontWeight: 500,
      "@media only screen and (max-width: 767px)": {
        color: "#333",
        fontSize: "12px", 
        fontWeight: 400,
        lineHeight: "normal",
      }
    },
    "& .invoice-amount": {
      fontSize: "20px", 
      fontWeight: 600,
      lineHeight: "30px",
      "@media only screen and (max-width: 767px)": {
        fontSize: "12px", 
        fontWeight: 700,
        lineHeight: "normal",
      }
    },
    "& .invoice-status": {
      display: "flex",
      width: "100px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "70px",
      background: "#019F48",
      color: "#FFF",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "30px",
      textTransform: "capitalize",
      "@media only screen and (max-width: 767px)": {
        fontSize: "12px",
        fontWeight: 700,
        width: "84px",
      }
    },
    "& .no-invoice-content": {
      marginTop: "-25px",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      height: "calc(100vh - 256px)",
      minHeight: "500px",
      "@media only screen and (max-width: 767px)": {
        height: "calc(100vh - 162px)",
        minHeight: "300px",
      }
    },
    "& .no-invoice-title": {
      fontSize: "38px", 
      fontWeight: 700,
      "@media only screen and (max-width: 767px)": {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "18px",
        color: "#292D32"
      }
    },
    "& .no-invoice-image": {
      "@media only screen and (max-width: 767px)": {
        height: "200px",
        width: "200px",
      }
    },
    "& .no-invoice-description": {
      color: "#9E9E9E",
      fontSize: "24px",
      lineHeight: "33px",
      marginTop: "10px",
      "@media only screen and (max-width: 767px)": {
        color: "#333",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "19px",
        marginTop: "8px",
      }    
    },
    "& .pointer": {
      cursor: "pointer"
    },
  },
  "& .select-menu": {
    display: "inline-flex",
    height: "34px",
    width: "80px",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #CEE1D6",
    background: "#F4FAF7",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    "& .MuiSelect-select": {
      height: "34px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "14px",
      background: "transparent",
      fontWeight: 500,
      fontFamily: "Urbanist",
      padding: 0,
      color: "#333",
      fontSize: "15px",
    }
  }
})

const MenuItemWrap = styled(MenuItem)({
  padding: 0,
    "@media screen and (max-width: 600px)" : {
        minHeight: "auto",
    },
    '&>div': {
        padding: "4px 14px",
        background: "#FFF",
        lineHeight: "normal",
        fontSize: "16px",
        fontFamily: "Urbanist",
        width: "100%",
        '&:hover': {
            background: 'rgba(1, 159, 72, 0.15)'
        }
    },
    '& .active-menu': {
        color: "#019F48",
        fontWeight: 600
    },
    '& .not-active-menu': {
        color: "#333",
        fontWeight: 500
    }
})

const ModalWrapper = styled(Modal)({
  "& .invoice-modal": {
    background: "#FFF",
    borderRadius: "20px",
    margin: "0",
    padding: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    width: "660px",
    "@media only screen and (max-width: 767px)": {
      borderRadius: "14px",
      width: "calc(100% - 48px)"
    },
    "& .download-report": {
      display: "inline-flex",
      height: "40px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "8px",
      background: "#019F48",
      color: "#FFF",
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "35px",
      textTransform: "none",
      width: "185px",
      "@media only screen and (max-width: 767px)": {
        height: "52px",
        width: "100%",
        fontWeight: 500
      }
    }
  },
  "& .invoice-modal-header": {
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media only screen and (max-width: 767px)": {
      padding: "16px"
    }
  },
  "& .invoice-modal-id-text": {
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "20px",
    fontWeight: 600, 
    lineHeight: "30px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "16px",
      lineHeight: "18px",
      color: "#292D32",
    }
  },
  "& .close-button": {
    cursor: "pointer",
    "@media only screen and (max-width: 767px)": {
      height: "12px",
      width: "12px"
    }
  },
  "& .button-section": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 30px 20px",
    "@media only screen and (max-width: 767px)": {
      padding: "16px 24px"
    }
  },
  "& .invoice-detail-section": {
    borderRadius: "0px 0px 20px 20px",
    background: "#E5EDE9",
  },
  "& .invoice-detail-show": {
    height: "400px",
    background: "#E5EDE9",
    padding: "30px 30px 0"
  }
})
// Customizable Area End
