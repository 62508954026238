import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgCreditLift, imgGraph, imgGroupProcess, imgWatch, txtLableStarted, imgDocUpload, imgDescription,  } from "./assets";
import { getStorageData,logoutUser,removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from 'moment';

export interface QuestionData {
  id: string;
  queId: number;
  question: string;
  answer: string;
}

interface AllQuestionResponseDetail {
  id: string;
  attributes: {
    question: string;
    id: number;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  // Customizable Area Start
  stepperValue: string[];
  basicInfo: any;
  questionsData: any;
  userDetail: any;
  documents: any;
  open: boolean;
  allQuestionList: QuestionData[];
  isQuestionUpdate: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ReviewBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateAccountAPIEndPoint: string = "";
  getAllQuestionAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      stepperValue: ['Basic Details','Questions','Upload Documents','Review','DocuSign'],
      basicInfo: {},
      userDetail:{},
      questionsData:[],
      isQuestionUpdate: false,
        documents:[],
        open:false,
        allQuestionList: [],
      
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    document.getElementsByClassName("topScroll")[0].scrollIntoView();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    let basicInfo = await getStorageData('basicInfo');
    let userDetail = await getStorageData('userData');
    const questionsAttributes= await getStorageData('questionsAttributes');
    const documents= await getStorageData('documents');
    this.setState({basicInfo: JSON.parse(basicInfo),
      userDetail:JSON.parse(userDetail),
      questionsData:JSON.parse(questionsAttributes),documents:JSON.parse(documents)})
    // Customizable Area End
  }

  // Customizable Area Start


  // Web Event Handling
  
  imgCreditLiftProps = {
    src: imgCreditLift,
  };
  txtLableStartedProps = {
    src: txtLableStarted,
  };
  imgGraphProps = {
    src: imgGraph,
  };
  imgWatcProps = {
    src: imgWatch,
  };
docUploadProps = {
    src: imgDocUpload,
  };
  imgGroupProcessProps = {
    src: imgGroupProcess,
  };

  descriptionOutlineProps = {
    src: imgDescription,
  };


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

 if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
       

        if (apiRequestCallId === this.updateAccountAPIEndPoint) {
          if (responseJson && responseJson.data) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveUserData(responseJson);
            this.goToLogin();
          } else {
            //Check Error Response
            alert(responseJson.errors)
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }else if(apiRequestCallId === this.getAllQuestionAPICallId){
            const data: QuestionData[] = this.getAllQuestionsData(responseJson);
            const questionData = await getStorageData('questionsAttributes');
            await this.handleAllQuestionsData(data, questionData);
        }
      }
    }
     // Customizable Area End
  }

  // Customizable Area Start

  handleAllQuestionsData = async (data: QuestionData[], questionData: string | undefined) => {
    const questionsData: boolean[] = [];
    const questionObjData: QuestionData[] = questionData ? JSON.parse(questionData) : [];
    const noFilteredData: string[] = [];
    if(questionData && data.length === questionObjData.length) {
      data.forEach((quesData, index ) => {
        if(quesData.id === questionObjData[index].id && quesData.queId === questionObjData[index].queId && quesData.question === questionObjData[index].question){
          questionsData.push(true)
        }else{
          questionsData.push(false)
        }
      })
    }else if(questionData && data.length < questionObjData.length){
      const newQueDataIds: QuestionData[] = [];
      data.forEach((quesData, index) => {
        const filteredData = questionObjData.find((queData: QuestionData) => queData.id === quesData.id);
        if(filteredData && filteredData.id === quesData.id && quesData.queId === filteredData.queId && quesData.question === filteredData.question){
          questionsData.push(true)
          newQueDataIds.push(filteredData);
        }else{
          questionsData.push(false)
        }
        if(index === data.length - 1 && !questionsData.includes(false)){
          setStorageData('questionsAttributes', JSON.stringify(newQueDataIds));
        }
      })
    }else{
      questionsData.push(false)
    }
    if(questionsData.includes(false)){
      this.setState({ open: true, isQuestionUpdate: true })
    }else{
      this.props.navigation.navigate('Docusign')
      this.setState({ isQuestionUpdate: false })
    }
  }

  getAllQuestionsData = (responseJSON: { data: AllQuestionResponseDetail[] }) => {
    if(responseJSON.data.length > 0){
      const data = responseJSON.data.map((question) => {
        const { id, attributes } = question;
        return {
          id: id,
          queId: attributes.id,
          question: attributes.question,
          answer: ''
        }
      })
      return data;
    }
    return [];
  }

  saveUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }
  goToLogin = () => {
   this.setState({open:true});

  }

  getAllQuestionsDataList = () => {
    const getQuestionsRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllQuestionAPICallId = getQuestionsRequestMessage.messageId;
    getQuestionsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllQuestionsEndpoint
    );

    getQuestionsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getQuestionsRequestMessage.id, getQuestionsRequestMessage);
  }

  getQuestionsData = async () => {
    this.getAllQuestionsDataList();
    const questionsData = await getStorageData('questionsAttributes');
    if(questionsData && JSON.parse(questionsData).length > 0){
      this.setState({ allQuestionList: JSON.parse(questionsData)});
    }
  }

  updateAccount = async() => {
    const basicInfo= await getStorageData('basicInfo');
    const questionsAttributes = await getStorageData('questionsAttributes');
    let questionData = [];
    if(questionsAttributes && JSON.parse(questionsAttributes).length > 0){
      questionData = JSON.parse(questionsAttributes).map((question: QuestionData) => ({
        question_id: question.queId,
        answer: [question.answer]
      }))
    }

    const documents= await getStorageData('documents');
    const userId= await getStorageData('userId');
    const data = {
      type: "email_account",
      "attributes": {
        "custom_form_attributes": JSON.parse(basicInfo),
        "question_answers_attributes": questionData,
        "documents": JSON.parse(documents),
        "is_completed": true
    }
    };
  
    const httpBody = {
      data: data,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAccountAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccountAPIEndPoint}/${userId}`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
      }
      closeModal=()=>{
        this.setState({open:!this.state.open});
        if(this.state.isQuestionUpdate){
          setStorageData('isQuestionUpdateNeeded', 'true');
          const questionBankMessage: Message = new Message(
            getName(MessageEnum.NavigationQuestionBankMessage)
          );
          questionBankMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(questionBankMessage);
        }else{
          removeStorageData('basicInfo');
          removeStorageData('questionsAttributes');
          removeStorageData('documents');
          removeStorageData('userId');
          removeStorageData('userDetail');
          const msg: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
          );
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(msg);
          logoutUser();
        }
      }

    getFormattedDob = () => moment(this.state?.basicInfo?.birthday, 'YYYY-MM-DD').format('DD-MM-YYYY');
  // Customizable Area End

}