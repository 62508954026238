// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock.web'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Cftransunioncreditintegration from "../../blocks/cftransunioncreditintegration/src/Cftransunioncreditintegration";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Cfintegrationwithcreditscoreexperian from "../../blocks/cfintegrationwithcreditscoreexperian/src/Cfintegrationwithcreditscoreexperian";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Cfequifaxcreditintegration1 from "../../blocks/cfequifaxcreditintegration1/src/Cfequifaxcreditintegration1";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import Aboutus from "../../blocks/contactus/src/AboutUs.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import QuestionBank from "../../blocks/questionbank/src/QuestionBankBlock.web";
import Notifications from "../../blocks/notifications/src/Notifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import TaskDetail from "../../blocks/tasks/src/TaskDetail.web";
import TaskDetailView from "../../blocks/tasks/src/TaskDetailView.web";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Meeting from "../../blocks/scheduling/src/Scheduling.web";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import Report from "../../blocks/dashboard/src/Report.web";
import Profile from "../../blocks/dashboard/src/UserProfile.web";
import DocSign from "../../blocks/docusignintegration/src/DocusignIntegration.web"
import Review from '../../blocks/docusignintegration/src/ReviewBlock.web';
import DocumentUpload from "../../blocks/bulkuploading/src/BulkUploading.web"

const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword/:navigationBarTitleText?"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions/:navigationBarTitleText?"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
DocumentUpload:{
 component:DocumentUpload,
path:"/DocumentUpload"
},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Cftransunioncreditintegration:{
 component:Cftransunioncreditintegration,
path:"/Cftransunioncreditintegration"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Cfintegrationwithcreditscoreexperian:{
 component:Cfintegrationwithcreditscoreexperian,
path:"/Cfintegrationwithcreditscoreexperian"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Cfequifaxcreditintegration1:{
 component:Cfequifaxcreditintegration1,
path:"/Cfequifaxcreditintegration1"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
AboutUs:{
 component:Aboutus,
path:"/AboutUs"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
  component:Dashboard,
 path:"/Dashboard"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Profile:{
  component:Profile,
path:"/Profile"},
Report:{
  component:Report,
path:"/Report"},
TaskDetail:{
  component:TaskDetail,
path:"/TaskDetail/:navigationBarTitleText?"},
TaskDetailView:{
  component:TaskDetailView,
path:"/TaskDetailView/:navigationBarTitleText?"},
Report:{
  component:Report,
path:"/Report"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Meeting:{
  component:Meeting,
path:"/Meeting"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Docusign:{
  component:DocSign,
 path:"/Docusign"},
 Review:{
  component:Review,
 path:"/Review"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  PrivacyPage: {
    component: TermsConditions,
    path:"/PrivacyPage/:navigationBarTitleText?"
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ width: '100%', height: '100vh', maxHeight: '100vh', overflow: 'auto' }}>
        <div>
          {WebRoutesGenerator({ routeMap })}
        </div>
        <ModalContainer />
      </View>
    );
  }
}

export default App;