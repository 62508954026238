import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from 'react';
import { imgPasswordInVisible, imgPasswordVisible, imgCreditLift, imgGraph, imgUploadDoc, imgWatch, imgWelcome, view_Vertical_line } from "./assets";
import { setStorageData, getStorageData, logoutUser, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface PasswordErrorData {
  character: boolean;
  smallCharacter: boolean;
  capitalCharacter: boolean;
  numberSymbol: boolean;
}

export interface FieldErrorData {
  acceptTermsAndCondition: boolean;
  nameError: boolean;
  emailError: boolean;
  passwordError: PasswordErrorData;
  confirmPasswordError: boolean;
  phoneError: boolean;
  userTypeError : boolean;
  processError:boolean;
}

export interface S {
  // Customizable Area Start
  name: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableRePasswordField:boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  firstName: string;
  lastName: string;
  fieldError: FieldErrorData
  passwordError: boolean;
  checked: boolean;
  passwordErrorShow: boolean;
  emailAlreadyExistError: boolean;
  userType : string;
  processError : boolean;
  loadingSignup: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  topRef: any;
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelName: string;
  labelEmail: string;

  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  labelUserType: string;
  btnTextSignUp: string;
  

  currentCountryCode: any;
  labelTitle: string = "";
  labelForSignup: string = "";
  // phoneNumberError: boolean
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      name: "",
      firstName:"",
      lastName:"",
      emailAlreadyExistError: false,
      processError: false,
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableRePasswordField:true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      userType: '',
      fieldError: {
        acceptTermsAndCondition: false,
        nameError: false,
        emailError: false,
        userTypeError: false,
        processError: false,
        passwordError: {
          character: false,
          smallCharacter: false,
          capitalCharacter: false,
          numberSymbol: false,
        },
        confirmPasswordError: false,
        phoneError: false,
      },
      checked: false,
      passwordError: false,
      passwordErrorShow: false,
      loadingSignup: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.topRef = React.createRef();
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.labelHeader = configJSON.labelHeader;
    this.labelName = configJSON.labelName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.labelTitle = configJSON.labelTitle;
    this.labelForSignup = configJSON.labelForSignup;
    this.labelUserType = configJSON.labelUserType;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const signupApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (signupApiRequestCallId && responseJson) {
        this.handleSignUpResponse(signupApiRequestCallId, responseJson, errorReponse)
      }
    }

    this.handleNavigationPayload(message);

    this.handleCountryCodeData(message);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.topRef.current.scrollIntoView({ behavior: 'smooth' });
    this.getUserData();
  }

  handleSignUpResponse = async (signupApiRequestCallId: any, responseJson: any, errorResponse: any) => {
    if (signupApiRequestCallId === this.validationApiCallId) {
      this.handleValidationResponse(responseJson);
    } else if (signupApiRequestCallId === this.createAccountApiCallId) {
      this.handleCreateAccountResponse(responseJson, errorResponse);
    }
  }
  
  handleValidationResponse = (responseJson: any) => {
    this.arrayholder = responseJson.data;
    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];
      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }
  
      if (regexData.password_validation_rules) {
        this.setState({ passwordHelperText: regexData.password_validation_rules });
      }
  
      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }
  
  handleCreateAccountResponse = async (responseJson: any, errorResponse: any) => {
    this.setState({ loadingSignup: false });
  
    if (!responseJson.errors) {
      await this.handleSuccessfulAccountCreation(responseJson);
    } else {
      this.handleAccountErrors(responseJson.errors);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }
  
  handleSuccessfulAccountCreation = async (responseJson: any) => {
    const msg: Message = new Message(getName(MessageEnum.AccoutResgistrationSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    await setStorageData('userData', JSON.stringify(responseJson.data.attributes));
    this.handleOnAccountClick();
  }
  
  handleAccountErrors = (errors: any) => {
    const accountErrorMessage = errors?.[0]?.account;
    if (accountErrorMessage === "Email already exists") {
      this.setState({ emailAlreadyExistError: true });
    } else if (accountErrorMessage === "Email invalid") {
      this.setState({ fieldError: { ...this.state.fieldError, emailError: true } });
    } else if (accountErrorMessage === "User process not followed, please follow the process") {
      this.setState({ fieldError: { ...this.state.fieldError, processError: true } });
    }
  }  

  isPasswordError = () => this.state.fieldError.passwordError && Object.values(this.state.fieldError.passwordError).includes(true) && this.state.passwordErrorShow;

  isEmailError = () => this.state.fieldError.emailError || this.state.emailAlreadyExistError

  handleNavigationPayload = (message: Message) => {
    if ( getName( MessageEnum.NavigationPayLoadMessage ) === message.id ) {
      const optToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if ( optToken && optToken.length > 0 ) {
        this.setState({ 
          otpAuthToken: optToken 
        });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);

        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);

      }
    }
  }

  handleTermsAndConditions = async () => {
    await this.handleSetUserData();
    this.goToTermsAndCondition();
  }

  handleSetUserData = async () => {
    const { name, email, password, reTypePassword, phone, checked } = this.state;
    const userData = {
      name,
      email,
      password,
      reTypePassword,
      phone,
      checked
    };
    await setStorageData('userData', JSON.stringify(userData));
  }

  getUserData = async () => {
    const userData = await getStorageData('userData');
    if(userData){
      const { name, email, password, reTypePassword, phone, checked } = JSON.parse(userData);
      this.setState({ name, email, password, reTypePassword, phone, checked });
    }
  }

  handleCountryCodeData = (message: Message) => {
    if (getName(MessageEnum.CountryCodeMessage) === message?.id) {
      const selectedCode = message?.getData(
        getName( MessageEnum.CountyCodeDataMessage )
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected: selectedCode.indexOf("+") > 0 ? selectedCode.split("+")[1] : selectedCode
        });

      }
    }
  }

  goToTermsAndCondition = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationTermAndConditionWeb));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.UrlPathParamMessage), 'web');
    this.send(msg);
  }

  getErrorText = (name: string) => `${name} is required`

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  getErrorName = () => {
    if(this.state.name.trim().length < 3){
      return configJSON.minCharacterNameErrorText;
    }else if(this.state.name.trim().length > 50){
      return configJSON.maxCharacterNameErrorText;
    }else{
      return this.getErrorText("Name");
    }
  }

  getPasswordError = (password: string): [ PasswordErrorData, boolean] => {
    const character = password.trim().length < 8;
    const smallCharacter = !configJSON.smallCharacterRegex.test(password);
    const capitalCharacter = !configJSON.capitalCharacterRegex.test(password);
    const numberSymbol = !configJSON.numberSymbolRegex.test(password);
    const passwordErrorData = {
      character,
      smallCharacter,
      capitalCharacter,
      numberSymbol
    }
    if(character || smallCharacter || capitalCharacter || numberSymbol){
      return [passwordErrorData, true]
    }
    return [passwordErrorData, false]
}

  handleValidation = () => {
    const errorObj = {...this.state.fieldError};
    const [ passwordErrorData, passwordError ] = this.getPasswordError(this.state.password);
    const confirmPasswordError = this.state.password !== this.state.reTypePassword;
    const termsConditionError = !this.state.checked;

    errorObj['nameError'] = this.isStringNullOrBlank(this.state.name) || !configJSON.nameRegex.test(this.state.name.trim());
    if(errorObj['nameError']){
      this.setState({ fieldError: errorObj })
      return false;
    }

    errorObj['emailError'] = this.isStringNullOrBlank(this.state.email) || !configJSON.emailRegex.test(this.state.email);
    if(errorObj['emailError']){
      this.setState({ fieldError: errorObj })
      return false;
    }

    errorObj['phoneError'] = this.isStringNullOrBlank(this.state.phone) || !configJSON.phoneRegex.test(this.state.phone);
    if(errorObj['phoneError']){
      this.setState({ fieldError: errorObj });
      return false;
    }  
    errorObj['passwordError'] = passwordErrorData;
    if(passwordError){
      this.setState({ fieldError: errorObj, passwordError });
      return false;
    }

    errorObj['confirmPasswordError'] = confirmPasswordError;
    if(confirmPasswordError){
      this.setState({ fieldError: errorObj });
      return false;
    }

    errorObj['acceptTermsAndCondition'] = termsConditionError;
    if(termsConditionError){
      this.setState({ fieldError: errorObj });
      return false;
    }
    errorObj['userTypeError'] = this.isStringNullOrBlank(this.state.userType);;
    if(errorObj['userTypeError']){
      this.setState({ fieldError: errorObj });
      return false;
    }
    this.setState({ fieldError: errorObj })
    return true
  }

  createAccount(): boolean {
    if(this.handleValidation()){
      this.setState({
        loadingSignup: true,
      })
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail
      };
    
      const attrs = {
        user_name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        full_phone_number:this.state.phone,
        password_confirmation:this.state.reTypePassword,
        terms_and_service: true,
        role_id : this.state.userType,
      };
    
      const bodyData = {
        type: "email_account",
        attributes: attrs
      };
    
      const httpBody = {
        data: bodyData,
        token: this.state.otpAuthToken
      };
    
      const createAccountRequestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
      
      this.createAccountApiCallId = createAccountRequestMessage.messageId;
      createAccountRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountsAPiEndPoint
      );
    
      createAccountRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      createAccountRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    
      createAccountRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      runEngine.sendMessage(createAccountRequestMessage.id, createAccountRequestMessage);
      return true;
    }
    return false;
  }

  handleOnClickTerms(){
    this.props.navigation.navigate('TermsConditionsDetail')
  }
  
  handleOnAccountClick = () => {
    const loginMessage: Message = new Message(
      getName(MessageEnum.NavigationInfoPageWebMessage)
    );
    loginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(loginMessage);
  }

  navigateToLogin = () => {
    const loginMessage: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    loginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(loginMessage);
  }
  
  getValidations() {
    const headers = { "Content-Type": configJSON.validationApiContentType };

    const getValidationsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validationApiCallId = getValidationsRequestMsg.messageId;

    getValidationsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsRequestMsg.id, getValidationsRequestMsg);
  }

  isNonNullAndEmpty(value: string) {
    return (value !== undefined && value !== null && value !== "null" && value.trim().length > 0);
  }

  validateCountryCodeAndPhoneNumber(countryCodeDetail: string, phoneNumberDetail: string) {
    let errors = null;
    if ( this.isNonNullAndEmpty(phoneNumberDetail) ) {
      if (!this.isNonNullAndEmpty(String(countryCodeDetail))) {
        errors=configJSON.errorCountryCodeNotSelected;
      }
    } else if ( this.isNonNullAndEmpty(countryCodeDetail) ) {
      if(!this.isNonNullAndEmpty(phoneNumberDetail)){
        errors = "Phone " + configJSON.errorBlankField;
      }
    }
    return errors;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: ()=> {
      this.setState({ enableReTypePasswordField: !this.state.enableReTypePasswordField });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state.enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this.txtInputConfirmPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible;
    }
  };

  imgCreditLiftProps = {
    source: imgCreditLift
  };
  imageCreditLiftProps = {
    src: imgCreditLift
  };
  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };
  imgGraphProps = {
    source: imgGraph
  };
  imagGraphProps = {
    src: imgGraph,
  };
  imgWatchProps = {
    source: imgWatch
  };
  imgUploadDocProps = {
    source: imgUploadDoc
  };
  imgWatcProps = {
    src: imgWatch,
  };
  imgUploadProps = {
    src: imgUploadDoc,
  };
  imgVerticalLineProps = {
    src: view_Vertical_line,
  };
  imgWelcomeTxtProps = {
    source: imgWelcome
  };
  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  handleInputeChange = async(e:any) =>{
    if (e.target.id === 'name' && (configJSON.nameChangeRegex.test(e.target.value) || e.target.value === "")) {
      this.setState({name:e.target.value, fieldError: { ...this.state.fieldError, nameError: false }})
    }
    else if (e.target.id === 'email') {
      this.setState({email:e.target.value,fieldError: { ...this.state.fieldError, emailError: false,processError: false } ,emailAlreadyExistError: false,})
    }
    else if (e.target.id === 'phoneNumber' && (/^\d+$/.test(e.target.value) || e.target.value === "")) {
      this.setState({phone:e.target.value,fieldError: { ...this.state.fieldError, phoneError: false }})
    }
    else if (e.target.id === 'password' && !e.target.value.includes(' ')) {
      const [ passwordErrorData] = this.getPasswordError(e.target.value);
      this.setState({password:e.target.value, fieldError: {...this.state.fieldError, passwordError: passwordErrorData }, passwordError:false})
    }
    else if (e.target.id === 'confirmPassword' && !e.target.value.includes(' ')) {
      this.setState({reTypePassword:e.target.value, fieldError: { ...this.state.fieldError, confirmPasswordError: false }})
    }
  }
  onAccept =() =>{
    this.setState({checked:!this.state.checked})
  }
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickShowRePassword = () => {
    this.setState({
      enableRePasswordField: !this.state.enableRePasswordField,
    });
  };
  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text});

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };
  setUserType =(value: string) =>{
    this.setState({ userType:value,fieldError: {...this.state.fieldError, userTypeError: false}})
  }
  // Customizable Area End
}
