import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgCreditLift, imgGraph, imgPasswordInVisible, imgPasswordVisible, imgGroupProcess, imgWatch, txtLableStarted,  } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React, { ChangeEvent } from "react";
import moment from 'moment';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  houseNumber: string;
  street: string;
  postalCode: string;
  country: string;
  stateData: string;
  genderType: "Male" | "Female" | null;
  dob: any;
  stepperValue: string[];
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  countryList: string[];
  stateList: string[];
  selectedCountry: string;
  stepper2:string[];
  userdata:any;
  errors: {
    houseNumber: boolean;
    street: boolean;
    postalCode: boolean;
    country: boolean;
    genderType: boolean;
    state: boolean;
    dob: boolean;
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class InfoPageBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  labelEmail: string ;
  labelPassword: string;
  getCountryDataCallId: string = "";
  getStateDataCallId: string = "";
  myref:any=React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    this.state = {
      selectedCountry: "United States of America",
      email: "",
      houseNumber: "",
      street:"",
      postalCode: "",
      password: "",
      country: "US",
      stepperValue:['Basic Details','Questions','Upload Documents','Review','DocuSign'],
      stepper2:['Basic Details','DocuSign'],
      userdata:JSON.parse(localStorage.getItem('userData')??'[]'),
      stateData: "",
      genderType: null,
      dob:'',
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      countryList: [],
      stateList: [],
      errors: {
        houseNumber: false,
        street: false,
        postalCode: false,
        country: false,
        genderType: false,
        state: false,
        dob: false,
      }
    };
    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    document.getElementsByClassName("topScroll")[0].scrollIntoView();
    const userData = JSON.parse(localStorage.getItem('userData')??'[]');
      this.setState({userdata:userData})
      this.getCountriesData();
      this.getStatesData('us');
      this.getBasicDetailData();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
  }

  handleValidation = () => {
    if(!this.state.houseNumber){
      this.setState({ errors: {...this.state.errors, houseNumber: true}})
      return;
    }else if(!this.state.country){
      this.setState({ errors: {...this.state.errors, country: true }})
      return;
    }else if(!this.state.street){
      this.setState({ errors: {...this.state.errors, street: true }})
      return
    }else if(!this.state.postalCode || !/^\d{5,6}$/.test(this.state.postalCode)){
      this.setState({ errors: {...this.state.errors, postalCode: true }})
      return;
    }else if(!this.state.stateData){
      this.setState({ errors: {...this.state.errors, state: true }})
      return;
    }else if(!this.state.dob || !this.isOlderThan18Years(this.state.dob)){
      this.setState({ errors: {...this.state.errors, dob: true }});
      return;
    }else if(!this.state.genderType){
      this.setState({ errors: {...this.state.errors, genderType: true}})
      return
    }
    this.submitBasicInfo();
  }


  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };
  setHouse = (text: string) => {
    this.setState({
      houseNumber: text,
    });
    if(text){
      this.setState({ errors: {...this.state.errors, houseNumber: false }})
    }
  };
  setStreet = (text: string) => {
    this.setState({
      street: text,
    });
    if(text){
      this.setState({ errors: {...this.state.errors, street: false }})
    }
  };
  setPostalCode = (text: string) => {
    if (!text || !/^\d{5,6}$/.test(text)) {
      this.setState({
        postalCode: text,
        errors: { ...this.state.errors, postalCode: true }
      });
    } else {
      this.setState({
        postalCode: text,
        errors: { ...this.state.errors, postalCode: false }
      });
    }
};

  setCountry =(value: string) =>{
    this.setState({
      selectedCountry: value,
    });
  };

  getCalendarMaxDate = () => {
    const currentDate = new Date();
    return new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate() + 1);
  }

  renderValue = (value: string, placeholder: string) => value ? value : placeholder

  getCalendarMinDate = () => {
    const currentDate = new Date();
    return new Date(currentDate.getFullYear() - 70, currentDate.getMonth(), currentDate.getDate());
  }

  getFormattedDate = () => {
    const date =  moment(this.state.dob, 'YYYY-MM-DD').format('DD-MM-YYYY');
    if(date !== "Invalid date"){
      return date;
    }
    return "";
  }

  goBack = async () => {
    await this.setBasicInfo();
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleCountryChange = (event: React.ChangeEvent<{}>,
    value: string | null | number | readonly string[],
    reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<string | number | readonly string[]> | undefined) => {
    const selectedOption = this.state.countryList.find(item => item[1] === value);
    if (value && selectedOption) {
        this.setState({ country: selectedOption[0], selectedCountry: selectedOption[1], stateData: '', errors: {...this.state.errors, country: false } });
    }
  }

  handleStateChange = (event: ChangeEvent<{}>, value: string | number | readonly string[] | null) => {
    if(value){
      this.setState({ stateData: value as string, errors: {...this.state.errors, state: false } });
    }
  }
  
  setStateValue =(value: string) =>{
    this.setState({
      stateData: value
    });
  };
  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };
  setGenderType =(value:string) =>{
    switch(value){
      case "Male":this.setState({genderType:"Male",errors: {...this.state.errors, genderType: false }});break;
      case "Female":this.setState({genderType:"Female",errors: {...this.state.errors, genderType: false }});break;
      default:this.setState({genderType:null});break;
    }
  }
  setDateOfBirth =(event: any)=>{
    this.setState({dob:event})
    if(event){
      this.setState({ errors: {...this.state.errors, houseNumber: false }})
    }
  }
  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

 

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };
  imgCreditLiftProps = {
    src: imgCreditLift,
  };
  txtLableStartedProps = {
    src: txtLableStarted,
  };
  imgGraphProps = {
    src: imgGraph,
  };
  imgWatcProps = {
    src: imgWatch,
  };
  imgGroupProcessProps = {
    src: imgGroupProcess,
  };
  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  getCountriesData = () => {
    const countryRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryDataCallId = countryRequestMessage.messageId;
    countryRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContriesDataUrl
    );

    countryRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(countryRequestMessage.id, countryRequestMessage);
  }

  getStatesData = (data: string) => {
    const stateRequestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStateDataCallId = stateRequestMessage.messageId;
    stateRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStatesDataUrl.replace(':state', 'us')
    );
    stateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(stateRequestMessage.id, stateRequestMessage);
  }

  getBasicDetailData = async () => {
    const customFormAttributes = await getStorageData('basicInfo');
    if(customFormAttributes){
      const data = JSON.parse(customFormAttributes);
      const { houseNumber, street, country, zip_code, state, gender, birthday, selectedCountry} = data;
      this.setState({
        houseNumber,
        selectedCountry,
        dob: birthday,
        genderType: gender,
        stateData: state,
        postalCode: zip_code,
        country,
        street,
      })
    }
  }

  isOlderThan18Years = (dateString: string) => {
    // Split the date string into year, month, and day
    const [year, month, day] = dateString.split('-').map(Number);
    
    // Create a Date object with the provided year, month, and day
    const dob = new Date(year, month - 1, day); // Subtract 1 from month as months are zero-based
    
    // Calculate current date and 18 years ago from today
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  
    // Compare the provided date with 18 years ago
    return dob <= eighteenYearsAgo;
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userdata = JSON.parse(localStorage.getItem('userData')??'[]')
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
      this.setState({userdata})
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if(apiRequestCallId === this.getCountryDataCallId) {
            if(responseJson && !responseJson.errors){
              this.setState({ countryList: responseJson })
            }
        }

        if(apiRequestCallId === this.getStateDataCallId){
          if(responseJson && !responseJson.errors){
            this.setState({ stateList: responseJson });
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.openInfoPage();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setBasicInfo = async () => {
    let custom_forms_attributes=  {
      "address": this.state.houseNumber,
      "street": this.state.street,
      "country": this.state.selectedCountry,
      "zip_code": this.state.postalCode,
      "state": this.state.stateData,
      "gender": this.state.genderType,
      "birthday": this.state.dob,
      "selectedCountry": this.state.selectedCountry,
      "houseNumber": this.state.houseNumber
    };
    await setStorageData('basicInfo', JSON.stringify(custom_forms_attributes));
  } 

  submitBasicInfo = async () => {
    if (Object.values(this.state.errors).some(error => error)) {
      return; // Do not proceed if there are errors
    }
    await this.setBasicInfo();
    if (JSON.parse(localStorage.getItem('userData')??'[]').role_id =='User') {
      this.navigateTo('QuestionBank');
      //this.props.navigation.navigate('QuestionBank')
      
    } else {
      //this.props.navigation.navigate('Docusign')
      this.navigateTo('Docusign');

    }
   
    
  }
  navigateTo = (title: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), title);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

     this.send(msg);
  };
  navigateToCreate = (Path :string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), Path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    
    this.send(message);
  };
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleKeyPress = (event: { charCode: any; preventDefault: () => void; }) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  // Customizable Area End
}