import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    styled,
    Divider,
    TextField,
    Modal,
    InputAdornment,
    MenuItem,
    MenuProps,
    Switch,
    Popper,
    PopperProps,
} from "@material-ui/core";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { calendarImg, checkedImg, closeImg, deleteImg, editImg, lockImg, uncheckedImg, changePasswordMobileImg, downArrowImg, activeIcon, notActiveIcon } from "./assets";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Customizable Area End

import UserProfileController, {
  Props,
  webConfigJSON,
} from "./UserProfileController.web";

export default class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getInputField = (label: string, name: string, value: string, isAddress: boolean, isError: boolean, errorText: string, errorFieldName: string) => {
    const getPostalCodeErrorText = (postalCode: string) => {
      let errorMessage = '';
      if (!postalCode) {
        errorMessage = 'Postal code is required';
      } else if (postalCode.length < 5) {
        errorMessage = 'Postal code should have at least 5 digits';
      } else if (postalCode.length > 6) {
        errorMessage = 'Postal code should not exceed 6 digits';
      }
      return errorMessage;
    };
  
    const postalCodeError = getPostalCodeErrorText(value);
  
    return (
      <InputFields>
        <label>{label}</label>
        <TextField
          onKeyPress={this.handleKeyPress}
          placeholder={label}
          data-test-id={`${name}-input`}
          className={this.getInputFieldError(isError)}
          disabled={!this.state.isEdit || name === "email"}
          variant="outlined"
          value={value}
          name={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(event, isAddress, errorFieldName)}
        />
        {isError && <Box className="error-text">{errorText}</Box>}
        {name === "postalCode" && this.state.errorData.postalCodeError && !value && (
          <Box className="error-text">Postal code is required</Box>
        )}
        {name === "postalCode" && value && postalCodeError && (
          <Box className="error-text">{postalCodeError}</Box>
        )}
      </InputFields>
    );
  }
  
  

  gettingTextInput = (params: AutocompleteRenderInputParams) => <TextField
    {...params}
    fullWidth
    placeholder="Country"
    variant="outlined"
  />

  getRenderOptions = (option: string | number | readonly string[], { selected }: { selected: any }) => (
    <MenuItemWrap title={option as string} disableRipple selected={selected}>
        <Box data-test-id="country-select-option" onClick={() => this.setCountry(option as string)} style={{ color: selected ? "#019F48" :"#333" }}>{option}</Box>
    </MenuItemWrap>
  );


  getStateLabelValue = () => this.state.tempUserData.address.state ? 
    <Box>{this.state.tempUserData.address.state}</Box> : <Box style={{ color: "rgba(51, 51, 51, 0.38)"}}>{webConfigJSON.stateLabel}</Box>;

  getSelectField = (label: string, options: string[][], isError: boolean, errorText: string) => (
    <InputFields className={isError? "select-error" : ""}>
      <label>{label}</label>
      <Autocomplete
                forcePopupIcon={false}
                title={this.state.selectedCountry}
                value={'USA'}
                options={options.map((option) => option[1])}
                // @ts-ignore
                onChange={this.handleCountryChange}
                className="auto-complete"
                disabled
                renderOption={this.getRenderOptions}
                {...menuProps}
                PopperComponent={this.gettingPopper}
                renderInput={this.gettingTextInput}
              />
      {isError && <Box className="error-text">{errorText}</Box>}
    </InputFields>
  )

  gettingPopper = (props: React.PropsWithChildren<PopperProps>) => <PopperWrapper {...props} />;

  gettingStateTextInput = (params: AutocompleteRenderInputParams) => <TextField
    {...params}
    fullWidth
    placeholder="State"
    variant="outlined"
  />;

  getRenderStateOptions = (option: string | number | readonly string[], { selected }: { selected: any }) => <MenuItemWrap disableRipple selected={selected}>
    <Box title={option as string} style={{ color: selected ? "#019F48" :"#333" }}>{option}</Box>
  </MenuItemWrap>

  getStateField = (label: string, value: string, option: string[], isError: boolean, errorText: string) => (
    <InputFields className={isError? "select-error" : ""}>
      <label>{label}</label>
      <Autocomplete
        value={value}
        title={value}
        options={option}
        disabled={!this.state.isEdit || !this.state.tempUserData.address.country}
        // @ts-ignore
        onChange={this.handleStateChange}
        data-test-id="state-input"
        className="auto-complete"
        renderOption={this.getRenderStateOptions}
        {...menuProps}
        PopperComponent={this.gettingPopper}
        renderInput={this.gettingStateTextInput}
        />
      {isError && <Box className="error-text">{errorText}</Box>}
    </InputFields>
  )


  getErrorContainer = (isError: boolean, text: string) => (
    <Box className="error-text-section">
      <img src={isError ? notActiveIcon : activeIcon} alt="" />
      <Box style={{ color: isError ? '#F00' : '#333' }}>{text}</Box>
    </Box>
  )

  getPasswordField = (label: string, name: string, value: string, showName: string, showPassword: boolean, isError: boolean, errorText: string) => (
    <InputFields style={{ position: this.getPositionStyle(name), gap: this.getGapStyle(name) }}>
      <Label>{label}</Label>
      <TextField
        data-test-id={`${name}-input`}
        variant="outlined"
        value={value}
        name={name}
        placeholder={label}
        type={showPassword ? "text" : "password"}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(event)}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        className={isError ? "password-error" : ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {showPassword ? <VisibilityOffOutlinedIcon data-test-id="visibility-off" className="pointer" htmlColor="#959a9b" onClick={() => this.handleChangeVisibility(showName, true)} /> 
              : <VisibilityOutlinedIcon data-test-id="visibility-on" className="pointer" htmlColor="#959a9b"  onClick={() => this.handleChangeVisibility(showName, false)} />}
            </InputAdornment>
          )
        }}  />
        {isError && <Box className="error-text">{errorText}</Box> }
        {name === "newPassword" && this.state.passwordData.newPasswordError && this.state.errorPopShow && (
          <PasswordErrorTextWrap>
            <PasswordErrorTextWrapper>
              <Box>{webConfigJSON.passwordErrorHeadingText}</Box>
              {this.getErrorContainer(this.state.newPasswordErrors.characterError, webConfigJSON.passwordCharacterErrorText)}
              {this.getErrorContainer(this.state.newPasswordErrors.smallCharacterError, webConfigJSON.passwordCharacterSmallErrorText)}
              {this.getErrorContainer(this.state.newPasswordErrors.capitalCharacterError, webConfigJSON.passwordCapitalErrorText)}
              {this.getErrorContainer(this.state.newPasswordErrors.numberSymbolError, webConfigJSON.passwordNumberSymbolErrorText)}
            </PasswordErrorTextWrapper>
          </PasswordErrorTextWrap>
        )}
    </InputFields>
  )

  getDateField = (label: string, name: string, value: string, isError: boolean, errorText: string) => (
    <InputFields position={"relative"}>
      <label>{label}</label>
      <DatePicker
      selected={value ? new Date(value) : null}
      onChange={(date: Date | null) => {
        if (date) {
          const event = {
            target: {
              name,
              value: date.toISOString().split('T')[0]
            }
          };
          this.handleInputChange(event as React.ChangeEvent<HTMLInputElement>, false, 'birthDateError');
        }
      }}
      dateFormat="dd-MM-yyyy"
      minDate={this.getCalendarMinDate()}
      maxDate={this.getCalendarMaxDate()}
      className={this.state.dateError ? "input-error" : ""}
      data-test-id="date-input"
      disabled={!this.state.isEdit}
      customInput={
        <TextField
          id="date"
          fullWidth
          variant="outlined"
          error={isError}
          style={{ backgroundColor: '#F4FAF7' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img src ={calendarImg} alt="calenderlogo"/>
              </InputAdornment>
            ),
          }}
        />
      }
      />
      {isError && <Box className="error-text">{errorText}</Box>}
    </InputFields>
  )

  getRadio = (radioValue: string, value: string, text: string) => (
    <Box data-test-id={`${value}-input`} onClick={() => this.handleGenderChange(value)} className={`${radioValue === value ? "active-radio": "inactive-radio"}`} style={{ cursor: this.state.isEdit ? "pointer" : "auto"}}>
          {text}
          <img src={radioValue === value ? checkedImg : uncheckedImg} />
    </Box>
  )

  getRadioField = (label: string, value: string, isError: boolean, errorText: string) => (
    <InputFields>
      <label>{label}</label>
      <Box className="radio-group-container">
        {this.getRadio(value, "Male", webConfigJSON.maleText)}
        {this.getRadio(value, "Female", webConfigJSON.femaleText)}
      </Box>
      {isError && <Box className="error-text">{errorText}</Box>}
    </InputFields>
  )

  getConfirmModal = () => (
    <Box className="confirmation-modal">
      {
        this.state.modalType === "update-password-confirmation" ? 
        <React.Fragment>
          <img className="change-password-desktop" src={lockImg} alt="disable" />
          <img className="change-password-mobile" src={changePasswordMobileImg} alt="disable" />
        </React.Fragment>
        :
        <img className="disable-account-image" src={deleteImg} alt="password" />
      }
      <Box className="confirm-modal-title">{this.state.modalType === "update-password-confirmation" ? webConfigJSON.changePasswordText : webConfigJSON.disableAccountText}</Box>
      <Box className="confirm-modal-description">{this.state.modalType === "update-password-confirmation" ?
        webConfigJSON.changePassword : 
        <>
          {webConfigJSON.disableAccount}<br/>{webConfigJSON.disableAccountBrief}
        </>}
      </Box>
      <Box className="button-wrappers">
        <Button data-test-id="cancel-button" onClick={this.handleCancelActionButton} className="cancel-button">{webConfigJSON.cancelText}</Button>
        <Button data-test-id="confirm-button" onClick={this.handleConfirmAction} className={`${this.state.modalType === "update-password-confirmation" ?"confirm":"disabled"}-button`}>{this.state.modalType === "update-password-confirmation" ? webConfigJSON.confirmText : webConfigJSON.disableAccountText}</Button>
      </Box>
    </Box>
  )

  getModalContent = () => {
    if(this.state.modalType === "update-password"){
      return (<React.Fragment>
        <Box className="modal-title-container">
          <Box className="modal-title-text">{webConfigJSON.changePasswordText}</Box>
          <img data-test-id="close-modal-button" className="pointer close-image" src={closeImg} alt="close" onClick={this.clearPasswordData} />
        </Box>
        <Box className="modal-description-text">{webConfigJSON.changePasswordDescription}</Box>
        <Box>
          {this.getPasswordField('Current Password', 'currentPassword', this.state.passwordData.currentPassword, 'showCurrentPassword', this.state.passwordData.showCurrentPassword, this.state.passwordData.currentPasswordError, this.getCurrentPasswordErrorLabel())}
          {this.getPasswordField('New Password', 'newPassword', this.state.passwordData.newPassword, 'showNewPassword', this.state.passwordData.showNewPassword, this.state.passwordData.newPasswordError, this.getNewPasswordErrorLabel())}
          {this.getPasswordField('Confirm New Password', 'confirmPassword', this.state.passwordData.confirmPassword, 'showConfirmPassword', this.state.passwordData.showConfirmPassword, this.state.passwordData.confirmPasswordError, webConfigJSON.confirmPasswordErrorText)}
        </Box>
        <Button data-test-id="update-profile-button" className="update-profile-button" onClick={this.handleUpdatePassword}>{webConfigJSON.UpdatePasswordText}</Button>
      </React.Fragment>)
    }else{
      return this.getConfirmModal();
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ProfileWrapper>
        <ModalWrapper
          disableEscapeKeyDown
          open={(this.state.isModalOpen && this.state.modalType !== "")}
          onClose={this.preventClose}
        >
            <Box className="modal-container">
              {this.getModalContent()}
            </Box>
        </ModalWrapper>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          screenName="User Profile"
          isDrawer={true}
          isLogo={true}
          fullWidthNavigation={true}
          userName={this.state.userData.name}
          userProfilePic={this.state.userData.profilePic}
        />
        <ProfileSection>
          <Box className="profile-container">
            <Box className="profile-head-content">
              <Box className="title-text">{webConfigJSON.myProfileText}</Box>
              <Box className="notification-section"> 
                <SwitchWrapper
                  disableRipple
                  checked={this.state.isNotificationEnabled}
                  onChange={this.handleNotification}
                  name="notification"
                  data-test-id="notification"
                />
                <Box>{webConfigJSON.notificationLabel}</Box>
              </Box>
            </Box>
          <Box className="profile-header">
            <Box className="profile-header-wrapper">
              <Box className="profile-photo-section">
                {this.state.userData.profilePic ? 
                  <img className="profile-photo" src={this.state.userData.profilePic} alt={this.state.userData.name} />
                : <AccountCircleIcon style={{ fill: "#4474e0"}} className="profile-photo" />
                }
                <input
                  type="file"
                  id="contained-button-file"
                  accept=".jpg, .jpeg, .png, .heif, .heic"
                  onChange={this.handleFileRead}
                  style={{ display: 'none' }}
                  data-test-id="file-upload"
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span" className="edit-profile-photo image-upload">
                    <Box className="edit-profile-photo photo-content">
                      <img src={editImg} alt="change-photo" />
                    </Box>
                  </Button>
                </label>
                  
                
              </Box>
              <Box>
                <Box className="username-text">{this.state.userData.name}</Box>
                <Box className="profile-created-text">{webConfigJSON.createdOnText} {this.state.tempUserData.createdAt}</Box>
              </Box>
            </Box>
            <Button data-test-id="edit-profile-button" className={`${this.state.isEdit ? "save-profile-button" : "edit-profile-button"} action-button`} onClick={this.handleEdit}>{this.state.isEdit ? webConfigJSON.SaveProfileText : webConfigJSON.editProfileText}</Button>
          </Box>
          {this.state.imageValidateError && <Box sx={{ mb: 4, color: '#F00' }}>{webConfigJSON.fileErrorText}</Box>}
          <UserDetailsForm>
            <Box className="form-content">
              {this.getInputField('Name', 'name', this.state.tempUserData.name, false, this.state.errorData.nameError, this.getNameErrorText(), 'nameError' )}
              {this.getInputField('Email', 'email', this.state.tempUserData.email, false, this.state.errorData.emailError, webConfigJSON.emailErrorText, 'emailError' )}
              {this.getInputField('Phone Number', 'phoneNumber', this.state.tempUserData.phoneNumber, false, this.state.errorData.phoneNumberError, this.getPhoneErrorText(), 'phoneNumberError' )}
            </Box>
            <Box className="form-heading-text">{webConfigJSON.AddressDetailsText}</Box>
            <Box className="form-content"> 
              {this.getInputField('Building/house No', 'buildingNo', this.state.tempUserData.address.buildingNo, true, this.state.errorData.buildingNoError, webConfigJSON.houseNumberErrorText, 'buildingNoError' )}
              {this.getSelectField('Country', this.state.countryList, this.state.errorData.countryError, webConfigJSON.countryErrorText)}
              {this.getInputField('Street', 'street', this.state.tempUserData.address.street, true, this.state.errorData.streetError, webConfigJSON.streetErrorText, 'streetError' )}
              {this.getInputField('Postal Code', 'postalCode', this.state.tempUserData.address.postalCode, true, this.state.errorData.postalCodeError, webConfigJSON.postalCodeErrorText, 'postalCodeError' )}
              {this.getStateField('State', this.state.tempUserData.address.state, this.state.stateList, this.state.errorData.stateError, webConfigJSON.stateErrorText)}
            </Box>
            <Box className="form-heading-text">{webConfigJSON.otherDetailsText}</Box>
            <Box className="form-content">
              {this.getDateField('DOB', 'birthDate', this.state.tempUserData.birthDate, this.state.errorData.birthDateError, webConfigJSON.dobErrorText)}
              {this.getRadioField('Gender', this.state.tempUserData.gender, this.state.errorData.genderError, webConfigJSON.genderErrorText)}
            </Box>
          </UserDetailsForm>
          </Box>
          {!this.state.isEdit && <Divider className="divider" />}
          <Box className="profile-container">
            <Box className="button-wrapper">
              <ChangePasswordButton
                data-test-id="change-password-button"
                onClick={this.handleChangePassword}
                style={{ display: this.state.isEdit ? "none" : "block" }}
              >
                {webConfigJSON.changePasswordText}
              </ChangePasswordButton>
              <DisableAccountButton
                data-test-id="disable-account-button"
                onClick={this.handleDisableAccount}
                style={{ display: this.state.isEdit ? "none" : "block" }}
              >
                {webConfigJSON.disableAccountText}
              </DisableAccountButton>
              <Button
                className="save-profile-button action-button visible"
                onClick={this.handleEdit}
              >
                {this.state.isEdit
                  ? webConfigJSON.SaveProfileText
                  : webConfigJSON.editProfileText}
              </Button>
            </Box>
          </Box>
        </ProfileSection>
      </ProfileWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const menuProps: Partial<MenuProps> = {
  anchorOrigin:{
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin:{
    vertical: "top",
    horizontal: "left"
  },
  MenuListProps:{
    style: {
      padding: 0,
    },
  },
  PaperProps: {
    style: {
      marginTop: '4px',
      boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
      border: "1px solid rgba(1, 159, 72, 0.18)",
      maxHeight: "123px",
      borderRadius: "12px",
      background: "#FFF",
    }
  },
  getContentAnchorEl: null,
}

const MenuItemWrap = styled(MenuItem)({
  lineHeight: "normal",
  background: "#FFF !important",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  '&:hover': {
    background: 'rgba(1, 159, 72, 0.15) !important'
  }
})

const PopperWrapper = styled(Popper)({
  '& .MuiAutocomplete-paper': {
    border: '1px solid rgba(1, 159, 72, 0.18)',
    marginTop: '4px',
  },
  '& .MuiAutocomplete-option, .MuiAutocomplete-listbox': {
    padding: 0
  },
  '& .MuiListItem-root': {
    width: '100%'
  }
});

const ProfileWrapper = styled(Box)({
  boxSizing: "border-box",
  background: "#F3FAF7",
  fontFamily: "Urbanist",
  fontStyle: "normal",
  fontWeight: 400,
  color: "#333",
  height: "100%",
  "@media only screen and (max-width: 767px)": {
    background: "#FFF",
  },
  '& .select-error': {
    '& .MuiOutlinedInput-root': {
      border: "1px solid #F00 !important"
    }
  },
  '& .error-text': {
    color: '#F00',
    fontSize: "14px",
  },
  "& .action-button": {
    display: "flex",
    width: "114px",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    fontFamily: "Urbanist",
    fontSize: "16px",
    textTransform: "none",
    "@media only screen and (max-width: 767px)": {
      display: "none",
    },
  },
  "& .visible": {
    display: "none",
    "@media only screen and (max-width: 767px)": {
      display: "block !important",
    },
  },
  '& .auto-complete': {
    '& input': {
      height: '35px',
      boxSizing: 'border-box',
    }
  },
  "& .edit-profile-button": {
    border: "1px solid #019F48",
    background: "#FFF",
    color: "#019F48",
    fontWeight: 500,
  }, 
  "& .save-profile-button": {
    border: "none",
    color: "#FFF",
    background: "#019F48",
    fontWeight: 600,
  },
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    background: `url(${calendarImg}) no-repeat`,
    height: "24px",
    width: "24px"
  }
});

const PasswordErrorTextWrap = styled(Box)({
  top: "123px", 
  zIndex: 111,
  position: "absolute", 
  right: 0,
  "@media only screen and (max-width: 767px)": {
    top: "100px"
  }
})

const SwitchWrapper = styled(Switch)({
  width: '42px',
  height: '26px',
  margin: '8px',
  padding: '1px',
  color: '#FFF',
  "@media only screen and (max-width: 767px)": {
    padding: "2px"
  },
  '& .MuiButtonBase-root': {
    color: '#fff',
    padding: '1px'
  },
  '& .MuiSwitch-thumb': {
    width: "24px",
    height: "24px"
  },
  '& .MuiSwitch-switchBase': {
    color: '#bdbdbd',
  },
  '& .Mui-checked': {
    color: "#FFF",
    transform: 'translateX(16px)',
  },
  '& .Mui-checked + .MuiSwitch-track': {
    background: '#019F48',
    opacity: 1
  },
  '& .MuiSwitch-track': {
    height: '22px',
    borderRadius: "13px",
    border: '1px solid #bdbdbd',
    transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    opacity: 1,
    backgroundColor: '#fafafa'
  }
})

const PasswordErrorTextWrapper = styled(Box)({
  borderRadius: "22px",
  padding: "20px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "25px",
  border: "1px solid rgba(1, 159, 72, 0.18)",
  boxShadow: "0px 8px 30px rgba(11, 109, 55, 0.10)",
  background: "#FFF",
  fontFamily: "Urbanist",
  '& .error-text-section': {
    alignItems: "center",
    display: "flex",
    gap: "10px",
  }
});

const InputFields = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  "&>label": {
    color: "#333",
    fontSize: "18px",
    fontWeight: 500
  },
  '& .error-text': {
    marginTop: "-8px"
  },
  '& .dob-field-container': {
    position: "absolute", 
    top: "45px",
    zIndex: 1111,
    alignItems: "center",
    height: "40px",
    left: "16px",
    background: "#f4faf7",
    width: "calc(100% - 70px)",
    display: "flex",
    paddingLeft: "5px",
    "@media only screen and (max-width: 767px)" : {
      top: "42px",
      width: "calc(100% - 64px)",
    }
  },
  '& .down-arrow': {
    position: "absolute",
    right: "15px",
    pointerEvents: "none",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 20px",
    height: "56px",
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "35px",
    display: "flex",
    alignItems: "center",
    "@media only screen and (max-width: 767px)": {
      fontSize: "16px",
      color: "#363A3F",
      fontWeight: 500,
      padding: "16px"
    }
  },
  "& .select-wrap": {
    "& .MuiOutlinedInput-input": {
      "@media only screen and (max-width: 767px)": {
        padding: "0 20px"
      }
  },
},
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    border: "1px solid #CEE1D6",
    background: "#F4FAF7",
    height: "56px",
    "@media only screen and (max-width: 767px)": {
      height: "50px",
      borderRadius: "8px"
    }
  },
  "& .Mui-focused": {
    borderColor: "#019F48"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0) !important"
  },
  "& .input-error": {
    "& .Mui-focused, .MuiOutlinedInput-root": {
      borderColor: "#FF0000 !important"
    },
    
  }
})

const ProfileSection = styled(Box)({
  borderRadius: "20px",
  border: "1px solid rgba(1, 159, 72, 0.18)",
  background: "#FFF",
  boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
  padding: "30px 0",
  margin: "30px 50px",
  boxSizing: "border-box",
  "@media only screen and (max-width: 767px)": {
    margin: "25px",
  },
  "& .profile-container": {
    padding: "0 30px",
    "@media only screen and (max-width: 767px)": {
      padding: "0 16px",
    },
    '& .profile-head-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .notification-section': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '20px'
    }
  },
  "& .title-text": {
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "30px",
    "@media only screen and (max-width: 767px)": {
      textAlign: "center",
    },
  },
  "& .profile-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media only screen and (max-width: 767px)": {
      justifyContent: "center",
      margin: "20px 0 24px"
    },
  },
  "& .profile-photo-section": {
    position: "relative",
    margin: "25px 0 40px",
    "@media only screen and (max-width: 767px)": {
      margin: "0",
    },
  },
  "& .profile-photo": {
    height: "100px",
    width: "100px",
    borderRadius: "100px",
    "@media only screen and (max-width: 767px)": {
      height: "40px",
      width: "40px",
      borderRadius: "40px",
    },
  },
  "& .edit-profile-photo": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    border: "3px solid #FFF",
    height: "36px",
    borderRadius: "36px",
    position: "absolute",
    bottom: "0",
    right: "0",
    background: "#019F48",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      width: "15px",
      border: "1.5px solid #FFF",
      height: "15px",
      borderRadius: "15px",
    },
    "&>img": {
      height: "20px",
      width: "20px",
      "@media only screen and (max-width: 767px)": {
        height: "8px",
        width: "8px",
      },
    },
  },
  "& .photo-content": {
    right: "-2px",
    top: "-3px",
    "@media only screen and (max-width: 767px)": {
      right: "-1px",
      top: "-1px"
    }
  },
  "& .image-upload": {
    minWidth: "auto",
    "@media only screen and (max-width: 767px)": {
      padding: 0,
    }
  },
  "& .username-text": {
    lineHeight: "30px",
    fontWeight: 700,
    fontSize: "24px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "22px",
      lineHeight: "normal",
      height: "30px"
    }
  },
  "& .profile-created-text": {
    fontSize: "16px",
    lineHeight: "30px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "normal",
      opacity: 0.5
    }
  },
  "& .profile-header-wrapper": {
    display: "flex",
    gap: "25px",
    alignItems: "center",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      gap: "15px"
    }
  },
  "& .divider": {
    background: "#E1EDE7",
    margin: "30px 0",
    "@media only screen and (max-width: 767px)": {
      display: "none"
    }
  },
  "& .button-wrapper": {
    display: "flex",
    gap: "25px",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      gap: "10px",
      marginTop: "24px",
      "& button": {
        width: "100%",
      }
    },
  },
  "& .form-heading-text": {
    fontSize: "24px", 
    fontWeight: 700, 
    lineHeight: "35px",
    margin: "50px 0 30px",
    "@media only screen and (max-width: 767px)": {
      margin: "20px 0 20px",
      fontSize: "16px",
      lineHeight: "20px"
    }
  },
  "& .form-content": {
    display: "flex",
    gap: "30px",
    flexDirection: "row",
    flexWrap: "wrap",
    "&>div": {
      width: "calc(50% - 15px)"
    },
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      "&>div": {
        width: "100%"
      },
      "&>div:nth-child(1)": {
        order: 1
      },
      "&>div:nth-child(2)": {
        order: 4
      },
      "&>div:nth-child(3)": {
        order: 2
      },
      "&>div:nth-child(4)": {
        order: 5
      },
      "&>div:nth-child(5)": {
        order: 3
      }
    },
  },
  "& .radio-group-container": {
    display: "flex",
    gap: "30px",
    "&>div": {
      width: "calc(50% - 15px)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 20px",
      borderRadius: "12px",
      background: "#F4FAF7",
      height: "56px",
      boxSizing: "border-box",
      "@media only screen and (max-width: 767px)": {
        height: "50px"
      }
    }
  },
  "& .active-radio": {
      border: "1px solid #019F48",
      color: "#019F48",
      fontWeight: 600
  }, 
  "& .inactive-radio": {
      border: "1px solid #CEE1D6",
      color: "#9E9E9E",
      fontWeight: 400
  }
});

const UserDetailsForm = styled(Box)({

})

const ChangePasswordButton = styled(Button)({
  display: "flex",
  width: "168px",
  height: "45px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
  border: "1px solid #333",
  background: "#FFF",
  color: "#333",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "none",
  "@media only screen and (max-width: 767px)": {
    color: "#363A3F",
    height: "40px",
    display: "block !important"
  }
});

const DisableAccountButton = styled(Button)({
  borderRadius: "6px",
  background: "#FFF1F1",
  display: "flex",
  width: "148px",
  height: "45px", 
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  color: "#D00",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "none",
  "&:hover": {
    background: "#FFF1F1",
  },
  "@media only screen and (max-width: 767px)": {
    textDecorationLine: "underline",
    height: "40px",
    background: "#FFF",
    display: "block !important",
    "&:hover": {
      background: "#FFF",
    },
  },
});

const Label = styled("label")({
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "35px",
  marginTop: "10px"
})

const ModalWrapper = styled(Modal)({
  display: "flex",
  alignItems: 'center',
  justifyContent: 'center',
  color: "#333",
  fontFamily: "Urbanist",
  fontStyle: "normal",
  fontWeight: 400,
  "@media only screen and (max-width: 767px)": {
    color: "#292D32"
  },
  "& .pointer": {
    cursor: "pointer"
  },
  '& .error-text': {
    color: '#F00',
    fontSize: "14px",
  },
  "& .modal-container": {
    margin: "0 25px",
    background: "#FFF",
    borderRadius: "20px",
    boxShadow: "0px 0px 50px 0px rgba(0, 0, 0, 0.10)",
    padding: "35px",
    width: "100%",
    maxWidth: "635px",
    "@media only screen and (max-width: 767px)": {
      padding: "24px",
    },
  },
  "& .modal-title-container" : {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    "@media only screen and (max-width: 767px)": {
      marginBottom: "8px"
    },
  },
  "& .modal-title-text": {
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "35px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "18px",
    }
  },
  "& .close-image": {
    height: "16px",
    width: "16px",
    "@media only screen and (max-width: 767px)": {
      height: "12px",
      width: "12px"
    }
  },
  "& .modal-description-text": {
    fontSize: "24px", 
    fontWeight: 400, 
    lineHeight: "34px",
    "@media only screen and (max-width: 767px)": {
      color: "#333",
      fontSize: "12px", 
      fontWeight: 500, 
      lineHeight: "19px",
      opacity: 0.8
    }
  },
  "& button": {
    fontFamily: "Urbanist",
    borderRadius: "12px",
    marginTop: "25px",
    height: "56px",
    fontSize: "22px",
    lineHeight: "30px",
    "@media only screen and (max-width: 767px)": {
      marginTop: "24px"
    }
  },
  "& .update-profile-button": {
    border: "none",
    color: "#FFF",
    background: "#019F48",
    fontWeight: 600,
    width: "100%",
    textTransform: "none"
  },
  "& .confirm-modal-title": {
    fontSize: "38px", 
    fontWeight: 700, 
    lineHeight: "35px",
    marginTop: "30px",
    "@media only screen and (max-width: 767px)": {
      marginTop: "12px",
      fontSize: "16px", 
      fontWeight: 600, 
      lineHeight: "18px",
    }
  },
  "& .confirm-modal-description": {
    fontSize: "24px",
    lineHeight: "33px",
    marginTop: "30px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "12px",
      lineHeight: "19px",
      fontWeight: 500,
      opacity: 0.8,
      color: "#333",
      marginTop: "16px"
    }
  },
  "& .confirmation-modal": {
    textAlign: "center"
  },
  "& .button-wrappers": {
    display: "flex",
    gap: "30px", 
    "& button": {
      borderRadius: "12px",
      height: "56px",
      lineHeight: "30px",
      textTransform: "none",
      fontFamily: "Urbanist",
      width: "calc(50% - 15px)",
      fontSize: "22px",
      fontWeight: 600,
    },
    "@media only screen and (max-width: 767px)": {
      gap: "12px",
      "& button": {
        height: "52px",
        fontSize: "16px",
        fontWeight: 500,
        width: "calc(50% - 6px)"
      }
    } 
  },
  "& .cancel-button": {
    border: "1px solid #9E9E9E",
    background: "#FFF",
    color: "#333",
  },
  "& .confirm-button": {
    background: "#019F48",
    color: "#FFF"
  },
  "& .disabled-button": {
    background: "#D00",
    color: "#FFF"
  },
  "& .password-error": {
    "& .Mui-focused, .MuiOutlinedInput-root": {
      borderColor: "#FF0000 !important"
    },
  },
  "& .change-password-desktop": {
    display: "none",
    "@media only screen and (min-width: 768px)": {
      display: "inline"
    }
  },
  "& .change-password-mobile": {
    display: "none",
    "@media only screen and (max-width: 767px)": {
      display: "inline"
    }
  },
  "& .disable-account-image": {
    "@media only screen and (max-width: 767px)": {
      width: "50px",
      height: "50px"
    }
  }
})

// Customizable Area End
