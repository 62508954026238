import React from "react";

// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Box, styled, TextField, Select, MenuItem, Divider, InputAdornment, LinearProgress, CircularProgress, MenuProps } from "@material-ui/core";
import { dropdownIcon, emptyTaskIcon, inprogressIcon, searchNormalIcon, tickCircleIcon, timerPauseIcon, totalTasksIcon } from "./assets";
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Customizable Area End

import TaskDetailController, { Props, configJSON } from "./TaskDetailController.web";

export default class Tasks extends TaskDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getSearchIcon = () => this.state.isMobile ? <SearchIcon data-test-id="search-icons" className="search-icon" /> : <img className="search-icon" src={searchNormalIcon} />;

  getTopSectionCard = (label: string, img: string, background: string, score: number, value: string) => (
    <Box className="top-section-container" data-test-id={`tab-${value}`} onClick={() => this.handleTabData(label, value)}>
        <Box className="top-section-icon" style={{ background }}>
            <img src={img} alt={label} />
        </Box> 
        <Box>
        <Box className="top-title-text">{label}</Box>
            <Box className="score-text">{score}</Box>
        </Box>
    </Box>
  )

  getBottomSectionCard = (label: string, status: string,  updatedOn: string, description: string, percentage: number, id: string ) => (
     <Box data-test-id="status-section-card" onClick={() => this.state.roleType === "User" && this.handleDetailView(id)} className="task-content-container" style={{ borderLeftColor: this.getColor(status), background: this.getBackgroundColor(status) }}>
         <Box className="bottom-section-info-container">
            <Box className="bottom-section-card-title">{label} <span>{configJSON.textLastUpdated} <span className="updated-text">{updatedOn}</span></span></Box>
            <Box className="bottom-section-card-description">{description}</Box>
         </Box>
         <Box className="bottom-section-percenatage-container">
            <Box>
                <Box className="bottom-section-status">{status}</Box>
                <Box className="bottom-section-percentage">{percentage}%</Box>
            </Box>
            <LinearProgress className={`linear-progress status-${status.toLowerCase().split(' ').join('-')}`} variant="determinate" value={percentage} />
         </Box>
         <div className="circular-progress-wrapper">
            <CircularProgress
                className="inner-progress-bar"
                variant="determinate"
                style={{ color: this.getBackColor(status) }}
                size={30}
                thickness={4}
                value={100}
            />
            <CircularProgress
                className="outer-progress-bar"
                variant="determinate"
                style={{ color: this.getColor(status) }}
                size={30}
                thickness={4}
                value={percentage}
            />
            <Box className="circular-progress-label">{percentage}%</Box>
        </div>
     </Box>
  )

  getSelectIcon = () => <img className="dropdown-image" src={dropdownIcon} />;

  getSelectLabel = () => this.state.sortBy !== "" ? <Box>{this.state.sortLabel}</Box> : <Box>{configJSON.textSortBy}</Box>;

  getClassName = (value: string) =>  value === this.state.sortBy ? 'active' : 'not-active';

  getMenuItem = (value: string, label: string) => <MenuItemWrap data-test-id={`sort-${value}`} disableRipple onClick={() => this.setState({ sortLabel: label})} value={value}>
    <Box className={this.getClassName(value)}>{label}</Box>
  </MenuItemWrap>
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <React.Fragment>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          screenName="Tasks"
          fullWidthNavigation={true}
          isDrawer={true}
          isNotification={true}
          isLogo={true}
        />
        <TaskDetailWrapper>
            {this.state.roleType === "User" && <Box className="top-section">
                {this.getTopSectionCard(configJSON.textTotalTasks, totalTasksIcon, "#75C500", this.state.tasks.total, configJSON.allTasksText)}
                {this.getTopSectionCard(configJSON.textTasksPending , timerPauseIcon, "#F00", this.state.tasks.pending, configJSON.pendingText)}
                {this.getTopSectionCard(configJSON.textTasksInprogress, inprogressIcon, "#FF7500", this.state.tasks.inprogress, configJSON.inprogressText)}
                {this.getTopSectionCard(configJSON.textTasksCompleted, tickCircleIcon, "#019F48", this.state.tasks.completed, configJSON.completeText)}
                {this.getTopSectionCard(configJSON.textTaskNotStarted, tickCircleIcon, "#666666", this.state.tasks.notStarted, configJSON.notStartedText)}
            </Box>}
            <Box className="bottom-section">
                <Box className="bottom-section-content">
                    <Box className="go-back-wrapper">
                    {this.state.roleType === "Agent" && <ArrowBackIcon onClick={this.goBackToDashboard.bind(this)} className="go-back-arrow"/>}
                    <Box className="task-title">{this.state.roleType === "User" ? configJSON.textMyTasks : "All Tasks"}</Box>
                    </Box>
                    <Box className={`${this.state.expanded ? 'task-title-hide' : ''}  task-title-mobile`}>{this.state.selectedTabLabel}</Box>
                    <Box className={`${this.state.expanded ? 'full-width' : ''} bottom-right-content`}>
                    <TextField
                        className={this.state.searchError ? "search-input" : ""}
                        variant="outlined"
                        data-test-id="search-input"
                        style={{ width: this.getSearchInputWidth(), height: "35px" }} // Fixed height for TextField
                        placeholder={configJSON.textSearchTask}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onChange={this.handleSearchText}
                        value={this.state.searchText}
                        error={Boolean(this.state.searchError)}
                        helperText={ // Fixed height and padding for error message container
                            <span style={{ marginTop:"2px",fontSize:"14px",fontWeight: "500", height: "16px", display: "inline-block" }}>{this.state.searchError}</span>
                        }
                        InputProps={{
                            style: { color: this.state.searchError ? "#F00" : "inherit", height:"35px",fontSize:"15px",fontFamily:"Urbanist",background:"white"},
                            startAdornment: (
                            <InputAdornment 
                                position="start"
                                component ="div"
                                className="search-start-adornment"
                            >
                                {this.getSearchIcon()}
                            </InputAdornment>
                            )
                        }}
                        />

                        <Select
                            className="select-sort"
                            variant="outlined"
                            displayEmpty
                            IconComponent={this.getSelectIcon}
                            renderValue={this.getSelectLabel}
                            value={this.state.sortBy}
                            MenuProps={sortMenuProps}
                            onChange={this.handleSelect}
                            data-test-id="select-sort"
                        >
                            {this.getMenuItem(configJSON.weeklyText, configJSON.weekText)}
                            {this.getMenuItem(configJSON.monthlyText, configJSON.monthText)}
                            {this.getMenuItem(configJSON.yearlyText, configJSON.yearText)}
                        </Select>
                    </Box>
                </Box>
                <Box className="status-tab-container">
                    {this.statusData.map((item) => (
                        <Box
                            key={item.value}
                            data-test-id="tab"
                            className={`${ item.value === this.state.selectedTab ? 'acitve-task-status-label' : 'task-status-label'}`} 
                            onClick={() => this.handleStatusTab(item.value)}>
                            {item.label}
                        </Box>
                    ))}
                </Box>
                <Divider className="divider" />
                <Box className="bottom-section-card">
                    {this.state.taskData.length > 0 ? 
                        this.state.taskData.map((task) => this.getBottomSectionCard(task.title, task.status, task.updatedOn, task.description, task.percentage, task.id))
                        :
                        <Box data-test-id="empty-content" className="no-task-container">
                            <img src={emptyTaskIcon} alt="no-task" />
                            <Box className="title">{configJSON.textForNoTaskTitle}</Box>
                            <Box className="description">{configJSON.textForNoTaskDescription}</Box>
                        </Box>
                    }
                </Box> 
            </Box>
            
        </TaskDetailWrapper>
      </React.Fragment>
    ); 
    // Customizable Area End
  }
}

// Customizable Area Start

const sortMenuProps: Partial<MenuProps> = {
    transformOrigin:{
      horizontal: "left",
      vertical: "top",
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        background: "#FFF",
        marginTop: "4px",
        boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
        borderRadius: "4px",
        border: "1px solid rgba(1, 159, 72, 0.18)",
      }
    },
    MenuListProps: {
        style: {
            padding: 0
        }
    }
  }

  const MenuItemWrap = styled(MenuItem)({
    padding: 0,
    "@media screen and (max-width: 600px)" : {
        minHeight: "auto"
    },
    '&>div': {
        fontSize: "16px",
        fontFamily: "Urbanist",
        width: "100%",
        padding: "4px 14px",
        background: "#FFF",
        lineHeight: "normal",
        '&:hover': {
            background: 'rgba(1, 159, 72, 0.15)'
        }
    },
    '& .active': {
        color: "#019F48",
        fontWeight: 600
    },
    '& .not-active': {
        color: "#333",
        fontWeight: 500
    }
  })

const TaskDetailWrapper = styled(Box)({
    background: "#F3FAF7",
    padding: "30px 50px",
    minHeight: "calc(100vh - 160px)",
    fontFamily: "Urbanist",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#333",
    "@media screen and (max-width: 767px)" : {
        minHeight: 'calc(100vh - 72px)',
        padding: "24px",
        color: "#282D32",
        boxSizing: "border-box"
    },
    "& .top-section": {
        display: "flex",
        gap: "20px",
        overflow: "auto",
        marginRight: "-50px",
        padding: "0 50px 20px 0",
        "@media screen and (max-width: 767px)" : {
            gap: "12px",
            marginRight: "-24px",
            padding: "0 24px 10px 0",
        },
        "& .top-title-text": {
            color: "#9E9E9E",
            fontWeight: 600,
            lineHeight: "30px",
            fontSize: "20px",
            "@media screen and (max-width: 767px)" : {
                color: "#292D32",
                fontWeight: 400,
                lineHeight: "18px",
                fontSize: "12px",
            }
        },
        "& .score-text": {
            fontWeight: 700,
            fontSize: "38px",
            lineHeight: "normal",
            "@media screen and (max-width: 767px)" : {
                fontWeight: 600,
                lineHeight: "16px",
                fontSize: "18px",
            }
        },
        "& .top-section-icon": {
            height: "60px",
            width: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            "@media screen and (max-width: 767px)" : {
                height: "34px",
                width: "34px",
                "&>img": {
                    height: "22.7px",
                    width: "22.7px"
                }
            },
        },
        "& .top-section-container": {
            cursor: "pointer",
            display: "flex",
            gap: "30px",
            padding: "20px",
            alignItems: "center",
            background: "#FFF",
            borderRadius: "12px",
            border: "1px solid rgba(1, 159, 72, 0.18)",
            boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
            minWidth: "270px",
            "@media screen and (max-width: 767px)" : {
                padding: "12px",
                minWidth: "115px", 
                gap: "8px",
                flexDirection: "column",
                alignItems: "start"
            }
        }
    },
    "& .bottom-section": {
        marginTop: "10px",
        borderRadius: "20px",
        border: "1px solid rgba(1, 159, 72, 0.18)",
        background: "#FFF",
        boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
        padding: "30px",
        "@media screen and (max-width: 767px)" : {
            padding: "12px"
        }
    },
    "& .bottom-section-content": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "15px",
        alignItems: "center"
    },
    "& .go-back-wrapper":{
        display: "flex",
        alignItems: "center"
    },
    "& .go-back-arrow":{
        cursor: 'pointer'
    },
    "& .task-title": {
        fontSize: "22px",
        fontWeight: 700,
        lineHeight: "30px",
        "@media screen and (max-width: 767px)" : {
            display: "none",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "18px",
        }
    },
    "& .task-title-mobile": {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "18px",
        "@media screen and (min-width: 768px)" : {
            display: "none",
        }
    },
    "& .task-title-hide": {
        "@media screen and (max-width: 480px)": {
            width: 0,
            visibility: 'hidden'
        }
    },

    "& .full-width": {
        "@media screen and (max-width: 480px)": {
            width: "100%",
        }
    },
    "& .task-status-label": {
        color: "#9E9E9E",
        fontSize: "18px",
        fontWeight: 500,
        "@media screen and (max-width: 900px)" : {
            fontSize: "14px",
        }
    },
    "& .acitve-task-status-label": { 
        color: "#019F48",
        fontSize: "20px",
        fontWeight: 600,
        borderBottom: "3px solid #019F48",
        "@media screen and (max-width: 900px)" : {
            fontSize: "15px",
        }
    },
    "& .bottom-right-content": {
        display: "flex",
        gap: "15px",
        "@media screen and (max-width: 767px)" : {
            gap: "8px"
        },
        '& .MuiOutlinedInput-adornedStart': {
            cursor: "pointer"
        }
    },
    "& .MuiOutlinedInput-adornedStart": {
        "@media screen and (max-width: 767px)" : {
            paddingLeft: "6px"
        },
    },
    "& .step-done-icon": {
        "@media screen and (max-width: 767px)" : {
            height: "22px",
            width: "22px"
        },
    },
    "& .search-input": {
        borderRadius: "4px",
        background: "#FFF",
        height: "34px",
        transition: 'width 0.3s ease',
        '& .search-start-adornment': {
            padding: "0 2px 0 1px",
            "@media screen and (max-width: 767px)" : {
                padding: "0 2px 0 5px",
                marginRight: "4px"
            },
            '& .search-icon': {
                pointerEvents: "none",
                cursor: "pointer",
            },
        },
        "& .MuiInputBase-input": {
            padding: "0 14px 0 0",
            height: "34px",
            color: "#333",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "Urbanist",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
        },
    },
    "& .select-sort": {
        borderRadius: "4px",
        border: "1px solid #CEE1D6",
        background: "#F4FAF7",
        display: "inline-flex",
        height: "36px",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        width: "100px",
        '& .dropdown-image': {
            position: "absolute",
            right: "14px",
            pointerEvents: "none"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
        },
        "& .MuiSelect-select": {
            background: "transparent",
            padding: "0 14px",
            color: "#333",
            fontSize: "15px",
            fontWeight: 500,
            fontFamily: "Urbanist",
            height: "36px",
            lineHeight: "36px",
        }
    },
    "& .status-tab-container": {
        display: "flex",
        gap: "20px",
        overflow: "auto",
        "&>div": {
            padding: "0 24px 5px",
            cursor: "pointer",
            "@media screen and (max-width: 900px)" : {
                padding: "0 20px 5px",
            }
        },
        "@media screen and (max-width: 767px)" : {
            display: "none"
        }
    },
    "& .divider": {
        "@media screen and (max-width: 767px)" : {
            display: "none"
        }
    },
    "& .bottom-section-card": {
        display: "flex",
        flexDirection: "column",
        margin: "30px 0",
        gap: "30px",
        "@media screen and (max-width: 767px)" : {
            gap: "12px",
            margin: "16px 0",
            maxHeight: 'calc(100vh - 356px)'
        },
        "&>.task-content-container": {
            borderLeft: "3px solid #019F48",
            background: "#F5FFFB",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "8px",
            padding: "20px 30px",
            cursor: "pointer",
            "@media screen and (max-width: 767px)" : {
                padding: "12px"
            },
            "& .bottom-section-info-container": {
                width: '60%',
                paddingRight: '20px',
                boxSizing: "border-box",
                "@media screen and (max-width: 767px)" : {
                    width: "calc(100% - 30px)"
                },
            },
            "& .bottom-section-card-title": {
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "30px",
                "@media screen and (max-width: 767px)" : {
                    fontWeight: 700,
                    marginBottom: "7px",
                    fontSize: "12px",
                    lineHeight: "normal",
                },
                "&>span": {
                    fontWeight: 500,
                    color: "#9E9E9E",
                    fontSize: "16px",
                    display: 'block',
                    "@media screen and (max-width: 767px)" : {
                        fontSize: "8px",
                        color: "#87898C",
                        '& .updated-text': {
                            fontWeight: 700,
                        }
                    }
               },
            },
            "& .bottom-section-card-description": {
                lineHeight: "35px",
                fontSize: "18px",
                "@media screen and (max-width: 767px)" : {
                    lineHeight: "normal",
                    fontSize: "12px",
                }
            },
            "& .bottom-section-percenatage-container": {
                maxWidth: "425px",
                width: "40%",
                "@media screen and (max-width: 767px)" : {
                    display: "none"
                },
                "&>div": {
                    justifyContent: "space-between",
                    marginBottom: "15px",
                    display: "flex",
                },
                
            }
        }
    },
    "& .bottom-section-status": {
        lineHeight: "30px",
        fontWeight: 500,
        fontSize: "16px",
    },
    "& .bottom-section-percentage": {
        fontSize: "16px",
        lineHeight: "30px",
        fontWeight: 600
    },
    "& .linear-progress": {
        background: "#D9D9D9",
        borderRadius: "30px",
        height: "10px"
    },
    "& .status-pending": {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#FF0000"
        }
    },
    "& .status-not-started": {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#D9D9D9"
        }
    },
    "& .status-inprogress": {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#FD8D02"
        }
    },
    "& .status-completed": {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#019F48"
        }
    },
    "& .no-task-container": {
        textAlign: "center",
        margin: "60px 0",
        '&>img': {
            "@media screen and (max-width: 767px)" : {
                width: "125px"
            },
        },
        "&>.title": {
            fontSize: "38px",
            fontWeight: 700,
            marginTop: "30px",
            "@media screen and (max-width: 767px)" : {
                fontSize: "16px",
                fontWeight: 600,
            },
        },
        "&>.description": {
            fontSize: "24px",
            lineHeight: "33px",
            color: "#9E9E9E",
            "@media screen and (max-width: 767px)" : {
                fontSize: "12px",
                color: "#333",
                lineHeight: "normal"
            },
        }
    },
    "& .circular-progress-wrapper": {
        display: "block",
        position: "relative",
        "&>div": {
            width: "30px",
            height: "30px",
        },
        "& .inner-progress-bar": {
            position: "absolute",
            top: 0,
            right: 0,
            transform: "rotate(-270deg) !important"
        },
        "& .outer-progress-bar": {
            position: "absolute",
            top: 0,
            right: 0,
            transform: "rotate(-270deg) !important",
            "& circle": {
                strokeLinecap: "round"
            }
        },
        "& .circular-progress-label": {
            position: "absolute",
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            right: 0,
            fontSize: "7px",
            fontWeight: 600
        },
        "@media screen and (min-width: 768px)" : {
            display: "none"
        },
    }
})
// Customizable Area End
